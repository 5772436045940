import { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import PackageCard from "../Items/Cards/package/PackageCard";
import useAxiosPost from "../../hooks/useAxiosPost";
import { formatDate } from "../../helpers/date";
import "./index.css";
import { useNavigate } from "react-router-dom";
const PackagesComponent = () => {
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("packages/getMyPackages");
  const [allPackages, setAllPackages] = useState<PackageViewCardProp[]>([]);
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const responseData: any = apiResponse.responseData;
      console.log({ responseData });
      const res = responseData || [];
      setAllPackages(
        res.map((item: any): PackageViewCardProp => {
          const _dateCreated = formatDate(item.dateCreated).toString();
          const volume = showVolume(
            item.maxDataVolumeLimit,
            item.maxDataVolumeUnit
          );
          return {
            packageName: item.packageName,
            dateCreated: _dateCreated,
            speed: getSpeed(item.uploadSpeed, item.rxTxSpeedUnit),
            enable: Boolean(Number(item.enable)),
            intPkCompanyID: Number(item.intPkCompanyID),
            intPkPackageID: Number(item.intPkPackageID),
            intPkTariffID: Number(item.intPkTariffID),
            dataVolume: volume,
            price: item.price,
            tariffName: item.tariffName,
          };
        })
      );
      //let's build packages
    }
  }, [apiResponse]);
  const getSpeed = (speed: string, rxTxSpeedUnit: string) => {
    return `${speed}${getSpeedUnit(rxTxSpeedUnit)}`;
  };
  const getSpeedUnit = (unit: string) => {
    if (unit === "G") {
      return "GB";
    }
    if (unit === "M") {
      return "MB";
    }
    return "Kb";
  };
  const showVolume = (maxDataVolumeLimit: any, maxDataVolumeUnit: any) => {
    if (
      maxDataVolumeUnit === null ||
      maxDataVolumeLimit == null ||
      maxDataVolumeLimit == "null" ||
      maxDataVolumeUnit == "null"
    ) {
      return "Unlimited";
    }
    return `${maxDataVolumeLimit}${getSpeedUnit(maxDataVolumeUnit)}`;
  };
  return (
    <>
      <Row
        gutter={16}
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate("/new-package");
          }}
          type="primary"
          className="login-submit-button"
        >
          Create Package
        </Button>
      </Row>
      <Row gutter={[16, 16]}>
        {allPackages.map((item: PackageViewCardProp) => (
          <Col span={6}>
            <PackageCard {...item} />
          </Col>
        ))}
        {/* <PackageCard /> */}
      </Row>
    </>
  );
};
export default PackagesComponent;
