import { useEffect, useLayoutEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Button, Checkbox } from "antd";
import "./styles/Login.css";
import ForgotPasswordImage from "./../../assets/pngs/UpdatePasswordBG.png";
import LogoImage from "./../../assets/pngs/logo/Logo.png";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
const ResetPasswordComponent = () => {
  useLayoutEffect(() => {
    if (!sessionStorage.getItem("email") || !sessionStorage.getItem("otp")) {
      window.location.href = "/forgot-password";
    }
  }, []);
  const navigate = useNavigate();
  const { openNotification, contextHolder } = useMultiNotification();
  const [
    postLoginData,
    loginResponseData,
    loginError,
    loginLoading,
    loginMessage,
    loginErrorCount,
  ] = useAxiosPost<ResetPasswordApiProp>("auth/resetPassword");

  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    const { password, confirmPassword } = values;
    if (password !== confirmPassword) {
      openNotification(`error`, "Validation Error", "Password Mismatch");
      return;
    }
    const dataToSend: ResetPasswordApiProp = {
      email: sessionStorage.getItem("email") || "",
      code: sessionStorage.getItem("otp") || "",
      password,
    };
    console.log({ dataToSend });
    postLoginData(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (loginError !== null && loginErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", loginError);
    }
  }, [loginError, loginErrorCount]);
  useEffect(() => {
    if (loginResponseData !== null) {
      window.location.href = "/login";
    }
  }, [loginResponseData]);
  return (
    <>
      {contextHolder}
      <div className="login-page-background">
        <div className="login-container">
          <div className="login-items">
            <Row gutter={[16, 1]} style={{ height: "100%" }}>
              <Col span={12}>
                <div className="login-form-right-container">
                  <div className="logo-login-form">
                    <img src={LogoImage} alt="Logo Image" />
                  </div>
                  <Typography className="login-form-title">
                    Reset Password
                  </Typography>
                  <Typography className="login-form-description">
                    We will help you change your password, Please provide us
                    email address you provided when sign up
                  </Typography>
                  <Form
                    className="login-form"
                    name="emailForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    requiredMark={false}
                  >
                    <Form.Item
                      label={
                        <span className="login-form-input-label">Password</span>
                      }
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter New Password",
                        },
                        {
                          min: 6,
                          message:
                            "Minimum 6 characters are required for password",
                        },
                        {
                          max: 20,
                          message:
                            "Maximum 20 characters are required for password",
                        },
                      ]}
                      style={{ marginBottom: "8px", color: "blue" }}
                      className="login-form-input-control"
                    >
                      <Input.Password
                        className="login-form-input-item"
                        placeholder="Enter your password"
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <span className="login-form-input-label">
                          Confirm Password
                        </span>
                      }
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please enter New Password",
                        },
                        {
                          min: 6,
                          message:
                            "Minimum 6 characters are required for password",
                        },
                        {
                          max: 20,
                          message:
                            "Maximum 20 characters are required for password",
                        },
                      ]}
                      style={{ marginBottom: "8px", color: "blue" }}
                      className="login-form-input-control"
                    >
                      <Input.Password
                        className="login-form-input-item"
                        placeholder="Enter your password"
                      />
                    </Form.Item>

                    <div className="remember-me-forgot-password-container">
                      <div className="remember-me-container"></div>
                      <div className="forgot-password-container">
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/login");
                          }}
                          className="forgot-password-label"
                        >
                          Login
                        </span>
                      </div>
                    </div>

                    <Form.Item>
                      <Button
                        className="login-submit-button"
                        type="primary"
                        htmlType="submit"
                      >
                        Recover Password
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
              <Col span={12}>
                <div className="login-right-container">
                  <img src={ForgotPasswordImage} alt="FORGOT PASSWORD Image" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResetPasswordComponent;
