import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import UserListComponent from "../components/users/UsersComponent";

const UsersListPage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="Users"
        routes={[{ title: "Home", path: "/" }]}
      />
      <UserListComponent />
    </>
  );
};
export default UsersListPage;
