import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Checkbox,
  Input,
  Typography,
  Select,
} from "antd";
import useAxiosPost from "../../hooks/useAxiosPost";
import "./index.css";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
interface SelectOptions {
  value: string;
  label: string;
}
const CreatePackageComponent = () => {
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<PackageCreationProp>("packages/createNewPackage");
  const { openNotification, contextHolder } = useMultiNotification();

  const [allTariffs, setAllTariffs] = useState<any>([]);
  const [tariffOptions, setTariffOptions] = useState<SelectOptions[]>([]);
  const [limitEnable, setLimitEnable] = useState<boolean>(false);

  const [
    getTariffsApi,
    tariffsApiResponse,
    tariffsApiError,
    tariffsApiLoading,
    tariffsApiMessage,
    tariffsApiApiErrorCount,
  ] = useAxiosPost<ResponseProp>("tariffs/getMyCompanyTariffs");
  useEffect(() => {
    if (tariffsApiResponse !== null) {
      // userType, email
      const responseData: any = tariffsApiResponse.responseData;
      console.log({ responseData });
      setAllTariffs(responseData || []);
      const res = responseData || [];
      setTariffOptions(
        res.map((item: any) => {
          return {
            label: item.tariffName,
            value: item.intPkTariffID,
          };
        })
      );
    }
  }, [tariffsApiResponse]);
  useEffect(() => {
    getTariffsApi({});
  }, []);
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    console.log("Received values:", values);
    const { packageName, validity, speed, volumeLimit, unit } = values;

    const dataToSend = {
      packageName,
      intPkTariffID: Number(validity),
      downloadSpeed: speed,
      uploadSpeed: speed,
      rxTxSpeedUnit: "M",
      maxDataVolumeLimit: volumeLimit || null,
      maxDataVolumeUnit: unit || null,
    };
    postApiData(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiErrorCount, apiErrorCount]);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const { message } = apiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate("/packages");
        }, 4000);
      }
    }
  }, [apiResponse]);
  return (
    <>
      {contextHolder}
      <Row className="create-package-container" gutter={[16, 16]}>
        <div className="general-form-container">
          <Typography className="general-form-title">Create Package</Typography>

          <Form
            // className="login-form"
            name="createPackageForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item
              label={<span className="package-input-label">Package Name</span>}
              name="packageName"
              rules={[
                {
                  required: true,
                  message: "Please enter your Package Name",
                },
                {
                  min: 4,
                  message: "Minimum Characters for package Name are 4",
                },
              ]}
              style={{ marginBottom: "8px", color: "blue" }}
              // className="login-form-input-control"
            >
              <Input
                className="package-form-input-item"
                placeholder="Enter Package Name"
              />
            </Form.Item>
            <Form.Item
              label={<span className="package-input-label">Validity</span>}
              name="validity"
              rules={[
                {
                  required: true,
                  message: "Please Choose Validity ",
                },
              ]}
              style={{ marginBottom: "8px", color: "blue" }}
            >
              <Select
                className="package-form-select-item"
                showSearch
                placeholder="Select Validity"
                optionFilterProp="children"
                filterOption={filterOption}
                options={tariffOptions}
              />
            </Form.Item>
            <Form.Item
              label={<span className="package-input-label">Speed</span>}
              name="speed"
              rules={[
                {
                  required: true,
                  message: "Please enter Speed",
                },
              ]}
              style={{ marginBottom: "8px", color: "blue" }}
              // className="login-form-input-control"
            >
              <Input
                className="package-form-input-item"
                placeholder="Enter Speed"
                suffix="MB"
              />
            </Form.Item>
            <div className="remember-me-forgot-password-container">
              <div className="remember-me-container">
                <Form.Item
                  name="enableDataLimit"
                  valuePropName="checked" // This is important for checkbox
                >
                  <Checkbox
                    value={limitEnable}
                    onChange={(e) => {
                      setLimitEnable(e.target.checked);
                    }}
                  >
                    <span className="login-form-input-label">
                      Enable Data Limit ?
                    </span>
                  </Checkbox>
                </Form.Item>
              </div>
            </div>
            {limitEnable == true ? (
              <>
                <div className="">
                  <div className="package-volume-container">
                    <Form.Item
                      label={
                        <span className="package-input-label">
                          Volume Limit
                        </span>
                      }
                      name="volumeLimit"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Volume Limit",
                        },
                      ]}
                      style={{ marginBottom: "8px", color: "blue" }}
                      // className="login-form-input-control"
                    >
                      <Input
                        className="package-form-input-item"
                        placeholder="Enter Volume"
                        type="number"
                      />
                    </Form.Item>
                    <Form.Item
                      label={<span className="package-input-label">Unit</span>}
                      name="unit"
                      rules={[
                        {
                          required: true,
                          message: "Please Choose Unit ",
                        },
                      ]}
                      style={{ marginBottom: "8px", color: "blue" }}
                    >
                      <Select
                        className="package-form-select-item"
                        placeholder="Unit"
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={[
                          {
                            value: "M",
                            label: "MB",
                          },
                          {
                            value: "G",
                            label: "GB",
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                </div>
              </>
            ) : null}

            <Form.Item>
              <Button
                className="login-submit-button"
                type="primary"
                htmlType="submit"
              >
                Create Package
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Row>
    </>
  );
};
export default CreatePackageComponent;
