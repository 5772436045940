import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import BatchesComponent from "../components/batches/ViewBatchesComponent";
const BatchesPage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="Batches"
        routes={[{ title: "Home", path: "/" }]}
      />
      <BatchesComponent />
    </>
  );
};
export default BatchesPage;
