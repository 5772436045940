import React from "react";
import "./index.css";
interface UserIndicator {
  isOnline: boolean;
}
const OnlineStatusIndicator: React.FC<UserIndicator> = ({ isOnline }) => {
  return <span className={isOnline ? "green-dot" : "red-dot"} />;
};

export default OnlineStatusIndicator;
