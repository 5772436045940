import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Card, Select, Button } from "antd";
import type { SelectProps } from "antd";
import "./details.css";
import BatchCard from "../Items/Cards/batch/BatchCard";
import BatchVouchersTable from "../Items/Table/BatchVouchersTable";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import { formatDateForJustMonthDayAndYear } from "../../helpers/date";
const BatchDetailsComponent = () => {
  const navigate = useNavigate();
  const { intPkBatchID } = useParams();
  const [vouchers, setVouchers] = useState<BatchVouchersTableDataType[]>([]);
  const [batchDetails, setBatchDetails] = useState<any>({});

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<VouchersByBatchProp>("batches/getSingleBatchDetails");
  useEffect(() => {
    postApiData({ intPkBatchID: Number(intPkBatchID) });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const responseData: any = apiResponse.responseData;

      setBatchDetails(responseData);
      const { vouchers } = responseData;
      const vouchersToShow = [];
      for (const voucher of vouchers) {
        vouchersToShow.push({
          voucherNumber: voucher.voucherNumber,
          status: true,
          intPkVoucherID: voucher.intPkVoucherID,
          voucherStatus: voucher.voucherStatus,
        });
      }
      setVouchers(vouchersToShow);
    }
  }, [apiResponse]);
  const getSpeed = (speed: string, rxTxSpeedUnit: string) => {
    return `${speed}${getSpeedUnit(rxTxSpeedUnit)}`;
  };
  const getSpeedUnit = (unit: string) => {
    if (unit === "G") {
      return "GB";
    }
    if (unit === "M") {
      return "MB";
    }
    return "Kb";
  };
  const showVolume = (maxDataVolumeLimit: any, maxDataVolumeUnit: any) => {
    if (
      maxDataVolumeUnit === null ||
      maxDataVolumeLimit == null ||
      maxDataVolumeLimit == "null" ||
      maxDataVolumeUnit == "null"
    ) {
      return "Unlimited";
    }
    return `${maxDataVolumeLimit}${getSpeedUnit(maxDataVolumeUnit)}`;
  };
  return (
    <>
      <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
        <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/create-batch`);
            }}
            className="fetch-devices-button"
            style={{ width: "200px" }}
          >
            Create Batch
          </Button>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={8}>
          <BatchCard
            tariffName={batchDetails.tariffName}
            batchName={batchDetails.batchName}
            isDisabled={!Boolean(Number(batchDetails.enable))}
            intPkBatchID={Number(batchDetails.intPkBatchID)}
            batchLength={Number(batchDetails.batchLength)}
            dateCreated={formatDateForJustMonthDayAndYear(
              new Date(batchDetails.dateCreated)
            ).toString()}
            speed={getSpeed(
              batchDetails.uploadSpeed,
              batchDetails.rxTxSpeedUnit
            )}
            dataVolume={showVolume(
              batchDetails.maxDataVolumeLimit,
              batchDetails.maxDataVolumeUnit
            )}
            usedVouchers={batchDetails?.batchStats?.totalUsedVouchers || 0}
            remainingVouchers={
              batchDetails?.batchStats?.totalUnusedVouchers || 0
            }
            batchStatus={batchDetails.batchStatus}
          />
        </Col>

        <Col span={16}>
          <BatchVouchersTable vouchers={vouchers} />
        </Col>
        {/* <span className="voucher-status-unused">Unused</span>
        <span className="voucher-status-used">Used</span> */}
      </Row>
    </>
  );
};
export default BatchDetailsComponent;
