import { useEffect, useState } from "react";
import { Row, Col } from "antd";
import SiteCard from "../Items/Cards/site/SiteCard";
import useAxiosPost from "../../hooks/useAxiosPost";

const SitesComponent = () => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("sites/getMySites");
  const [allSites, setAllSites] = useState<any>([]);
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const responseData: any = apiResponse.responseData;
      console.log({ responseData });
      setAllSites(responseData || []);
    }
  }, [apiResponse]);
  return (
    <>
      <Row gutter={[16, 16]}>
        {allSites.map((item: any) => {
          return (
            <Col key={Math.random()} span={12}>
              <SiteCard
                siteAddress={item.siteAddress}
                siteName={item.siteName}
                intPkCompanyID={Number(item.intPkCompanyID)}
                intPkCompanySiteID={Number(item.intPkCompanySiteID)}
              />
            </Col>
          );
        })}

        {/* <Col span={12}>
          <SiteCard />
        </Col> */}
      </Row>
    </>
  );
};
export default SitesComponent;
