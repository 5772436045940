// import React from 'react';
import HomeIcon from "./assets/svgs/MenuIcons/HomeIcon.svg";
import DevicesIcon from "./assets/svgs/MenuIcons/DevicesIcon.svg";
import FeedbackIcon from "./assets/svgs/MenuIcons/FeedbackIcon.svg";
import PackagesIcon from "./assets/svgs/MenuIcons/PackagesIcon.svg";
import PoliciesIcon from "./assets/svgs/MenuIcons/PoliciesIcon.svg";
import ProfileIcon from "./assets/svgs/MenuIcons/ProfileIcon.svg";
import QRCodeIcon from "./assets/svgs/MenuIcons/QRCodeIcon.svg";
import SitesIcon from "./assets/svgs/MenuIcons/SitesIcon.svg";
import UsersIcon from "./assets/svgs/MenuIcons/UsersIcon.svg";
import VoucherIcon from "./assets/svgs/MenuIcons/VoucherIcon.svg";

import MyComponent from "./MyComponent";
import HomePage from "./pages/HomePage";
import SitesPage from "./pages/SitesPage";
import PackagesPage from "./pages/PackagesPage";
import DevicesPage from "./pages/DevicesPage";
import SingleSitePage from "./pages/SingleSitePage";
import BatchesPage from "./pages/BatchesPage";
import BatchDetailsPage from "./pages/BatchDetailsPage";
import UserDetailsPage from "./pages/UserDetailsPage";
import UsersListPage from "./pages/UsersPage";
import MyProfilePage from "./pages/MyProfilePage";
import EditProfilePage from "./pages/EditProfilePage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import CreatePolicyPage from "./pages/CreatePolicyPage";
import CreatePackagePage from "./pages/CreatePackagePage";
import CreateBatchPage from "./pages/CreateBatchPage";
import CreateUserPage from "./pages/CreateUserPage";
import UserPoliciesPage from "./pages/UserPoliciesPage";
import AssignPackageToUserPage from "./pages/AssignPackageToUserPage";

const routesList: Array<Route> = [
  {
    path: "/",
    Component: HomePage,
    roles: ["COMPANY_ADMIN"],
    text: "Home",
    show: true,
    icon: HomeIcon,
  },
  {
    path: "/sites",
    Component: SitesPage,
    roles: ["COMPANY_ADMIN"],
    text: "Sites",
    show: true,
    icon: SitesIcon,
  },
  {
    path: "/site-details/:intPkCompanySiteID",
    Component: SingleSitePage,
    roles: ["COMPANY_ADMIN"],
    text: "Site details",
    show: false,
    icon: "",
  },
  {
    path: "/packages",
    Component: PackagesPage,
    roles: ["COMPANY_ADMIN"],
    text: "Packages",
    show: true,
    icon: PackagesIcon,
  },
  {
    path: "/new-package",
    Component: CreatePackagePage,
    roles: ["COMPANY_ADMIN"],
    text: "Create Package",
    show: false,
    icon: "",
  },
  {
    path: "/devices",
    Component: DevicesPage,
    roles: ["COMPANY_ADMIN"],
    text: "Devices",
    show: true,
    icon: DevicesIcon,
  },
  {
    path: "/policies",
    Component: UserPoliciesPage,
    roles: ["COMPANY_ADMIN"],
    text: "Policies",
    show: true,
    icon: PoliciesIcon,
  },
  {
    path: "/create-policy",
    Component: CreatePolicyPage,
    roles: ["COMPANY_ADMIN"],
    text: "Create Policy",
    show: false,
    icon: "",
  },
  {
    path: "/qr-code",
    Component: MyComponent,
    roles: ["COMPANY_ADMIN"],
    text: "QR Code",
    show: true,
    icon: QRCodeIcon,
  },
  {
    path: "/batches",
    Component: BatchesPage,
    roles: ["COMPANY_ADMIN"],
    text: "Vouchers",
    show: true,
    icon: VoucherIcon,
  },
  {
    path: "/batch-details/:intPkBatchID",
    Component: BatchDetailsPage,
    roles: ["COMPANY_ADMIN"],
    text: "Batch Details",
    show: false,
    icon: "",
  },
  {
    path: "/create-batch",
    Component: CreateBatchPage,
    roles: ["COMPANY_ADMIN"],
    text: "Create Batch",
    show: false,
    icon: "",
  },
  {
    path: "/users",
    Component: UsersListPage,
    roles: ["COMPANY_ADMIN"],
    text: "Users",
    show: true,
    icon: UsersIcon,
  },
  {
    path: "/create-user",
    Component: CreateUserPage,
    roles: ["COMPANY_ADMIN"],
    text: "Create User",
    show: false,
    icon: "",
  },

  {
    path: "/profile",
    Component: MyProfilePage,
    roles: ["COMPANY_ADMIN"],
    text: "Profile",
    show: true,
    icon: ProfileIcon,
  },
  {
    path: "/edit-profile",
    Component: EditProfilePage,
    roles: ["COMPANY_ADMIN"],
    text: "Profile",
    show: false,
    icon: "",
  },
  {
    path: "/change-password",
    Component: ChangePasswordPage,
    roles: ["COMPANY_ADMIN"],
    text: "Profile",
    show: false,
    icon: "",
  },

  {
    path: "/feedback",
    Component: MyComponent,
    roles: ["COMPANY_ADMIN"],
    text: "Feedback",
    show: true,
    icon: FeedbackIcon,
  },
  {
    path: "/user-details/:intPkClientID",
    Component: UserDetailsPage,
    roles: ["COMPANY_ADMIN"],
    text: "user details",
    show: false,
    icon: "",
  },
  {
    path: "/assign-package/:intPkClientID",
    Component: AssignPackageToUserPage,
    roles: ["COMPANY_ADMIN"],
    text: "Assign Package",
    show: false,
    icon: "",
  },
];
export const AppRoutes = () => {
  const role = "COMPANY_ADMIN";

  return routesList.filter((route: Route) => route.roles.includes(role));
};
