import { useState, Dispatch, SetStateAction } from "react";
import axios, { AxiosResponse, AxiosError } from "axios";
import { handleLogout } from "../helpers/userDetails";
// const baseURL='http://localhost:5011/api/v1'
export const baseURL =
  process.env.REACT_APP_API_URL || "https://backend.api.vezfi.com/api/v1";

export const authTokenString = localStorage.getItem("auth_token") || false;

interface ErrorResponse {
  error: string;
}

const useAxiosPost = <T>(
  initialUrl: string,
  isLoadingDelay: boolean = false
): [
  (body: T) => Promise<void>,
  T | null,
  string | null,
  boolean,
  string,
  number,
  Dispatch<SetStateAction<string>>
] => {
  const [url, setUrl] = useState(initialUrl);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errorCount, setErrorCount] = useState<number>(0);
  const API_URL = baseURL;
  const postData = async (body: T): Promise<void> => {
    setIsLoading(true);
    const axiosBody: any = {
      ...body,
      authTokenString,
    };
    try {
      const response: AxiosResponse<T> = await axios.post(
        `${API_URL}/${url}`,
        axiosBody,
        {
          headers: {
            Authorization: `Bearer ${authTokenString}`,
          },
        }
      );
      setData(response.data);
      setError(null);
    } catch (error: AxiosError<ErrorResponse> | any) {
      console.log(`Error Code`);
      if (
        Number(error?.response?.status) === 403 ||
        Number(error?.response?.status) === 401
      ) {
        //it means the call rejected with unauthorized so we need to logout this user.
        handleLogout();
      }
      setError(error.response?.data?.message || "Server Error");
      setErrorCount((prevCount) => prevCount + 1);
      setData(null);
    } finally {
      if (isLoadingDelay === true) {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } else {
        setIsLoading(false);
      }
    }
  };

  return [postData, data, error, isLoading, message, errorCount, setUrl];
};

export default useAxiosPost;
