import { Typography, Card } from "antd";
import "./boxItem.css";
const DashboardBoxItem: React.FC<DashboardBoxItemProp> = ({
  icon,
  counterValue,
  title,
}) => {
  return (
    <Card>
      <div className="box-container">
        <img src={icon} />
        <Typography className="box-item-heading">{title}</Typography>
      </div>
      <div className="box-item-counter">
        <Typography className="box-item-counter-text">
          {counterValue}
        </Typography>
      </div>
    </Card>
  );
};
export default DashboardBoxItem;
