import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Button, Checkbox } from "antd";
import "./styles/Login.css";
import LoginFormImage from "./../../assets/pngs/auth/LoginFormImage.png";
import LogoImage from "./../../assets/pngs/logo/Logo.png";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
const LoginComponent = () => {
  const navigate = useNavigate();

  const { openNotification, contextHolder } = useMultiNotification();

  const [
    postLoginData,
    loginResponseData,
    loginError,
    loginLoading,
    loginMessage,
    loginErrorCount,
  ] = useAxiosPost<LoginType>("auth/login");
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    console.log("Received values:", values);
    const { email, password } = values;
    console.log({ email, password });
    const dataToSend: LoginType = {
      email: email,
      password: password,
    };
    console.log({ dataToSend });
    postLoginData(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (loginError !== null && loginErrorCount !== 0) {
      openNotification(`error`, "Login Failed", loginError);
    }
  }, [loginError, loginErrorCount]);
  useEffect(() => {
    if (loginResponseData !== null) {
      // userType, email
      const responseData: any = loginResponseData.responseData;
      const { authToken, user } = responseData;
      localStorage.setItem("auth_token", authToken || "");
      localStorage.setItem("userType", user.userType || "");
      localStorage.setItem("email", user.email);
      window.location.href = "/";
    }
  }, [loginResponseData]);
  return (
    <>
      {contextHolder}
      <div className="login-page-background">
        <div className="login-container">
          <div className="login-items">
            <Row gutter={[16, 1]} style={{ height: "100%" }}>
              <Col span={12}>
                <div className="login-form-right-container">
                  <div className="logo-login-form">
                    <img src={LogoImage} alt="Logo Image" />
                  </div>
                  <Typography className="login-form-title">LOGIN</Typography>
                  <Typography className="login-form-description">
                    Welcome to HotSpotik. Sign in to access your account and
                    control your WiFi
                  </Typography>
                  <Form
                    className="login-form"
                    name="emailForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    requiredMark={false}
                  >
                    <Form.Item
                      label={
                        <span className="login-form-input-label">Email</span>
                      }
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email address",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid email address",
                        },
                      ]}
                      style={{ marginBottom: "8px", color: "blue" }}
                      className="login-form-input-control"
                    >
                      <Input
                        className="login-form-input-item"
                        placeholder="Enter your email"
                      />
                    </Form.Item>

                    <Form.Item
                      label={
                        <span className="login-form-input-label">Password</span>
                      }
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Password",
                        },
                        {
                          min: 6,
                          message: "Minimum Characters for password are 6",
                        },
                      ]}
                      style={{ marginBottom: "8px", color: "blue" }}
                      className="login-form-input-control"
                    >
                      <Input.Password
                        className="login-form-input-item"
                        placeholder="Enter your Password"
                      />
                    </Form.Item>
                    <div className="remember-me-forgot-password-container">
                      <div className="remember-me-container">
                        <Form.Item
                          name="agreement"
                          valuePropName="checked" // This is important for checkbox
                        >
                          <Checkbox>
                            <span className="login-form-input-label">
                              Remember Me
                            </span>
                          </Checkbox>
                        </Form.Item>
                      </div>
                      <div className="forgot-password-container">
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/forgot-password");
                          }}
                          className="forgot-password-label"
                        >
                          Forgot Password
                        </span>
                      </div>
                    </div>

                    <Form.Item>
                      <Button
                        className="login-submit-button"
                        type="primary"
                        htmlType="submit"
                      >
                        Login
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
              <Col span={12}>
                <div className="login-right-container">
                  <img src={LoginFormImage} alt="Login Image" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginComponent;
