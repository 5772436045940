import { Button } from "antd";

const MyComponent = () => {
  return (
    <>
      <Button type="primary">Click Me</Button>
      <p>Hello</p>
    </>
  );
};

export default MyComponent;
