import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import ViewPoliciesComponent from "../components/policies/ViewPoliciesComponent";

const UserPoliciesPage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="Policies"
        routes={[{ title: "Home", path: "/" }]}
      />
      <ViewPoliciesComponent />
    </>
  );
};
export default UserPoliciesPage;
