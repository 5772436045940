import React from "react";
import "./index.css";
import { Table, Switch } from "antd";

import type { ColumnsType } from "antd/es/table";

const BatchVouchersTable: React.FC<BatchVouchersTableProp> = ({ vouchers }) => {
  /** 
  status: string;
  intPkClientID: string | number; */

  const columns: ColumnsType<BatchVouchersTableDataType> = [
    {
      title: "Voucher Number",
      dataIndex: "voucherNumber",
      key: "voucherNumber",
    },

    {
      title: "",
      dataIndex: "voucherStatus",
      key: "voucherStatus",
      render: (_, { voucherStatus }) =>
        voucherStatus === "USED" ? (
          <span className="voucher-status-used">Used</span>
        ) : (
          <span className="voucher-status-unused">Unused</span>
        ),
    },

    {
      title: "",
      key: "action",
      render: (_, { intPkVoucherID, status }) => {
        return (
          <>
            <Switch checked={status} />
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="table-container-custom">
        <Table
          className="antd-table-striped"
          columns={columns}
          dataSource={vouchers}
          pagination={false}
        />
      </div>
    </>
  );
};

export default BatchVouchersTable;
