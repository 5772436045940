import React from "react";
import SettingsIcon from "./../../assets/svgs/NavBarIcons/SettingsIcon.svg";
import SearchIcon from "./../../assets/svgs/NavBarIcons/SearchIcon.svg";
import NotificationsIcon from "./../../assets/svgs/NavBarIcons/NotificationsIcon.svg";
import { Select } from "antd";
import type { SelectProps } from "antd";

const options: SelectProps["options"] = [
  {
    label: "Hamza Arshad",
    value: "1",
  },
];

const navItems: NavItem[] = [
  { label: "Settings", url: "/settings", icon: SettingsIcon },
  { label: "Search", url: "/search", icon: SearchIcon },
  { label: "Notification", url: "notifications", icon: NotificationsIcon },
];

const TopNavBar = () => {
  return (
    <>
      <div className="top-nav-bar-items-container">
        {navItems.map((item: NavItem) => {
          return (
            <div key={Math.random()} className="top-nav-bar-item">
              {<img src={item.icon} />}
            </div>
          );
        })}
        <Select
          size={"middle"}
          defaultValue="Hamza Arshad"
          //   onChange={handleChange}
          style={{ width: 200 }}
          options={options}
        />
      </div>
    </>
  );
};

export default TopNavBar;
