import { Typography, Card, Divider, Dropdown, Col, Row } from "antd";
import "./packageCard.css";
import MenuIcon from "./../../../../assets/svgs/OptionsMenuIcon/MenuIcon.svg";
import type { MenuProps } from "antd";

interface PackageCardCounterProp {
  label: string;
  value: number | string | null;
}

const PackageCardCounter: React.FC<PackageCardCounterProp> = ({
  label,
  value,
}) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={10} className="package-card-counters-label-container">
        <Typography className="label-field">{label}</Typography>
        <Typography className="label-field">:</Typography>
      </Col>
      <Col span={14}>
        <Typography className="package-card-counter-label-value">
          {value}
        </Typography>
      </Col>
    </Row>
  );
};
const PackageCard: React.FC<PackageViewCardProp> = ({
  dateCreated,
  speed,
  enable,
  intPkCompanyID,
  intPkPackageID,
  intPkTariffID,
  dataVolume,
  packageName,
  price,
  tariffName,
}) => {
  console.log({ dateCreated, speed });
  const onClick: MenuProps["onClick"] = ({ key }) => {
    console.log("Hi", key);
  };
  const items: MenuProps["items"] = [
    {
      key: "siteDetails",
      label: <span className="drop-down-item-custom">Site Details</span>,
    },

    {
      key: "refresh",
      label: <span className="drop-down-item-custom">Refresh</span>,
    },
  ];
  console.log({ dataVolume });

  return (
    <Card className="border-package-card">
      {/* Top Header */}
      <div className="package-card-top-items">
        <div className="package-card-title">
          <Typography className="package-header-title">
            {packageName}
          </Typography>
        </div>

        <div className="package-options-opener-container">
          <Dropdown
            className="custom-drop-down"
            menu={{ items, onClick }}
            trigger={["click"]}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <img className="cursor-pointer" src={MenuIcon} />
          </Dropdown>
        </div>
      </div>
      {/* Top Header End */}
      <PackageCardCounter label="Speed" value={speed} />
      <PackageCardCounter label="Duration" value={tariffName} />
      <PackageCardCounter label="Volume" value={dataVolume} />
      <PackageCardCounter label="Price" value={price} />
    </Card>
  );
};
export default PackageCard;
