import { useEffect, useLayoutEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Button, Checkbox } from "antd";
import OtpInput from "react-otp-input";

import "./styles/Login.css";
import OTPImage from "./../../assets/pngs/auth/OTPImage.png";
import LogoImage from "./../../assets/pngs/logo/Logo.png";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
const OTPInputComponent = () => {
  const [otp, setOtp] = useState("");

  useLayoutEffect(() => {
    if (!sessionStorage.getItem("email")) {
      window.location.href = "/forgot-password";
    }
  }, []);
  const navigate = useNavigate();
  const { openNotification, contextHolder } = useMultiNotification();
  const [
    postLoginData,
    loginResponseData,
    loginError,
    loginLoading,
    loginMessage,
    loginErrorCount,
  ] = useAxiosPost<VerifyCodeApiProp>("auth/verifyForgotCode");
  const onFinish = (email: string, otp: string) => {
    // Handle form submission, values.email will contain the entered email address
    const dataToSend: VerifyCodeApiProp = {
      email: email,
      code: otp,
    };
    console.log({ dataToSend });
    postLoginData(dataToSend);
  };

  useEffect(() => {
    if (loginError !== null && loginErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", loginError);
    }
  }, [loginError, loginErrorCount]);
  useEffect(() => {
    if (loginResponseData !== null) {
      sessionStorage.setItem("otp", otp);
      window.location.href = "/reset-password";
    }
  }, [loginResponseData]);

  return (
    <>
      {contextHolder}
      <div className="login-page-background">
        <div className="login-container">
          <div className="login-items">
            <Row gutter={[16, 1]} style={{ height: "100%" }}>
              <Col span={12}>
                <div className="login-form-right-container">
                  <div className="logo-login-form">
                    <img src={LogoImage} alt="Logo Image" />
                  </div>
                  <Typography className="login-form-title">
                    Enter OTP
                  </Typography>
                  <Typography className="login-form-description">
                    We have sent you an email with an OTP over your registered
                    email address. Please input the OTP below.
                  </Typography>
                  <Form
                    className="login-form"
                    name="emailForm"
                    layout="vertical"
                    requiredMark={false}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={8}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                        containerStyle={{
                          marginTop: "3vh",
                          marginBottom: "15vh",
                          minWidth: "80%",
                        }}
                        inputStyle={{
                          minWidth: "40px",
                          minHeight: "40px",
                          marginRight: "5px",
                          marginLeft: "5px",
                          borderRadius: "5px",
                          border: "1px solid #979797",
                        }}
                      />
                    </div>
                    <Form.Item>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          if (otp.length !== 8) {
                            openNotification(
                              "error",
                              "Validation Error",
                              "Invalid OTP"
                            );
                            return;
                          }
                          onFinish(sessionStorage.getItem("email") || "", otp);
                        }}
                        className="login-submit-button"
                        type="primary"
                        htmlType="submit"
                      >
                        Verify OTP
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
              <Col span={12}>
                <div className="login-right-container">
                  <img src={OTPImage} alt="OTP Image" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default OTPInputComponent;
