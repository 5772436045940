import { useEffect, useState } from "react";
import { Row, Col } from "antd";
import SiteCard from "../Items/Cards/site/SiteCard";
import SingleSiteCard from "../Items/Cards/site/SingleSiteCard";
import useAxiosPost from "../../hooks/useAxiosPost";
import { useParams } from "react-router-dom";
import { formatDate } from "../../helpers/date";

const SingleSiteComponent = () => {
  const { intPkCompanySiteID } = useParams();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<SingleSiteApiProp>("sites/getMySingleSite");
  useEffect(() => {
    postApiData({ intPkCompanySiteID: Number(intPkCompanySiteID) });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const responseData: any = apiResponse.responseData;
      console.log({ responseData });

      const {
        dateCreated,
        enable,
        intPkCompanyID,
        intPkCompanySiteID,
        siteAddress,
        siteName,
        routerDetails = null,
      } = responseData;
      const {
        coreRouterIp,
        coreRouterVendorName,
        eUserLocalNetworkLinkLogin,
        intPkCoreRouterVendorsID,
        intPkSiteCoreRouterID,
        routerName,
      } = routerDetails;
      const _dateCreated = formatDate(dateCreated).toString();
      const _routerDetails: RouterShortDetailsProp = {
        coreRouterIp,
        coreRouterVendorName,
        eUserLocalNetworkLinkLogin,
        intPkCoreRouterVendorsID,
        intPkSiteCoreRouterID,
        routerName,
      };
      setSiteDetails({
        enable: Boolean(Number(enable)),
        dateCreated: _dateCreated,
        intPkCompanyID: Number(intPkCompanyID),
        intPkCompanySiteID: Number(intPkCompanySiteID),
        siteAddress: siteAddress,
        siteName: siteName,
        routerDetails: routerDetails === null ? null : _routerDetails,
      });

      // setAllSites(responseData || []);
    }
  }, [apiResponse]);
  const [siteDetails, setSiteDetails] = useState<SingleSiteCardProp>({
    enable: false,
    dateCreated: "",
    siteName: "",
    siteAddress: "",
    intPkCompanySiteID: 0,
    intPkCompanyID: 0,
    routerDetails: {
      coreRouterIp: "",
      coreRouterVendorName: "",
      eUserLocalNetworkLinkLogin: "",
      intPkCoreRouterVendorsID: "",
      intPkSiteCoreRouterID: "",
      routerName: "",
    },
  });
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <SingleSiteCard {...siteDetails} />
        </Col>
      </Row>
    </>
  );
};
export default SingleSiteComponent;
