import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Card,
  Checkbox,
  Typography,
  Form,
} from "antd";
import "./index.css";
import RemoveIcon from "./../../assets/svgs/IconsBlack/RemoveIcon.svg";
import UnBlockIcon from "./../../assets/pngs/UnBlocked.png";
import BlockedIcon from "./../../assets/pngs/Blocked.png";
import CrossIcon from "./../../assets/pngs/CrossIcon.png";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
const CreatePolicyComponent = () => {
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreateTimePolicyApiProp>("policies/createNewUserTimePolicy");
  const { openNotification, contextHolder } = useMultiNotification();

  const initialDays = [
    {
      dayName: "Monday",
      usedBy: -1,
      id: 1,
    },
    {
      dayName: "Tuesday",
      usedBy: -1,
      id: 2,
    },
    {
      dayName: "Wednesday",
      usedBy: -1,
      id: 3,
    },
    {
      dayName: "Thursday",
      usedBy: -1,
      id: 4,
    },
    {
      dayName: "Friday",
      usedBy: -1,
      id: 5,
    },
    {
      dayName: "Saturday",
      usedBy: -1,
      id: 6,
    },
    {
      dayName: "Sunday",
      usedBy: -1,
      id: 7,
    },
  ];
  const [userPolicyName, setUserPolicyName] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [policyNameError, setPolicyNameError] = useState<string | null>(null);
  const [daysOfWeek, setDaysOfWeek] = useState<DayOfWeek[]>(initialDays);
  const [contentPolicies, setContentPolicies] = useState<ContentPolicyOption[]>(
    [
      {
        label: "Facebook",
        value: "fb",
      },
      {
        label: "Youtube",
        value: "yt",
      },
      {
        label: "Tiktok",
        value: "tiktok",
      },
      {
        label: "Instagram",
        value: "ig",
      },
    ]
  );
  const [schedule, setSchedule] = useState<DaySchedule[]>([]);
  const handleAddDay = () => {
    const newDay = {
      days: [],
      isContentPolicyAdded: false,
      blockedContent: {
        fb: 0,
        yt: 0,
        tiktok: 0,
        ig: 0,
      },
      timePairs: [
        {
          startTime: { hour: "", minute: "" },
          endTime: { hour: "", minute: "" },
          blockedContent: {
            fb: 0,
            yt: 0,
            tiktok: 0,
            ig: 0,
          },
        },
      ],
    };
    setSchedule([...schedule, newDay]);
  };
  const handleErrorMessage = (error: string) => {
    setError(error);
    setTimeout(() => {
      setError(null);
    }, 5000);
  };

  const handleAddTimePair = (dayIndex: number) => {
    const currentDay = schedule[dayIndex];
    if (doesTimeOverlap(currentDay.timePairs)) {
      handleErrorMessage("Cannot add new time pair due to existing overlap.");
      return;
    }
    const isValidTimePair = currentDay.timePairs.every((timePair) => {
      const startHour = parseInt(timePair.startTime.hour, 10) || 0;
      const startMinute = parseInt(timePair.startTime.minute, 10) || 0;
      const endHour = parseInt(timePair.endTime.hour, 10) || 0;
      const endMinute = parseInt(timePair.endTime.minute, 10) || 0;
      return (
        startHour < endHour ||
        (startHour === endHour && startMinute < endMinute)
      );
    });

    if (isValidTimePair) {
      const newTimePairs = [
        ...currentDay.timePairs,
        {
          startTime: { hour: "", minute: "" },
          endTime: { hour: "", minute: "" },
          blockedContent: {
            fb: 0,
            yt: 0,
            tiktok: 0,
            ig: 0,
          },
        },
      ];
      const updatedSchedule = [...schedule];
      updatedSchedule[dayIndex].timePairs = newTimePairs;
      setSchedule(updatedSchedule);
    } else {
      handleErrorMessage(
        "Invalid time pair. Start time must be less than end time."
      );
    }
  };

  const handleDayChange = (
    dayIndex: number,
    value: string,
    eventType: boolean,
    dayIdIndex: number
  ) => {
    if (eventType === true) {
      const updatedSchedule = [...schedule];
      updatedSchedule[dayIndex].days.push(value);
      setSchedule(updatedSchedule);
      const _dayOfWeek = [...daysOfWeek];
      _dayOfWeek[dayIdIndex - 1].usedBy = dayIndex;
      setDaysOfWeek(_dayOfWeek);
    } else if (eventType === false) {
      const updatedSchedule = [...schedule];
      updatedSchedule[dayIndex].days = updatedSchedule[dayIndex].days.filter(
        (item) => item !== value
      );

      setSchedule(updatedSchedule);
      const _dayOfWeek = [...daysOfWeek];
      _dayOfWeek[dayIdIndex - 1].usedBy = -1;
      setDaysOfWeek(_dayOfWeek);
    }
  };
  const handleRemoveTimePair = (dayIndex: number, timeIndex: number) => {
    const newSchedule = [...schedule];

    // Check if the day and time pair exists
    if (newSchedule[dayIndex] && newSchedule[dayIndex].timePairs) {
      // Remove the time pair
      newSchedule[dayIndex].timePairs.splice(timeIndex, 1);

      // Update the state with the new array
      setSchedule(newSchedule);
    } else {
      console.warn(`No Data Found`, dayIndex, timeIndex);
    }
  };

  const handleTimeChange = (
    dayIndex: number,
    timeIndex: number,
    type: "startTime" | "endTime",
    field: "hour" | "minute",
    value: string
  ) => {
    const updatedSchedule = [...schedule];
    const updatedTimePairs = [...updatedSchedule[dayIndex].timePairs];
    const updatedTimePair = { ...updatedTimePairs[timeIndex] };

    updatedTimePair[type][field] = value; // Apply the change
    updatedTimePairs[timeIndex] = updatedTimePair; // Update the time pair

    // Check for time overlap
    if (!doesTimeOverlap(updatedTimePairs)) {
      updatedSchedule[dayIndex].timePairs = updatedTimePairs; // Update schedule if no overlap
      setSchedule(updatedSchedule);
    } else {
      handleErrorMessage(
        "Time overlap detected. Please choose a different time."
      );
    }
  };
  const handleBlockContentChangeInTimeSlot = (
    dayIndex: number,
    timeIndex: number,
    eventType: boolean,
    label: string
  ) => {
    const updatedSchedule = [...schedule];
    const updatedTimePairs = [...updatedSchedule[dayIndex].timePairs];
    const updatedTimePair: any = { ...updatedTimePairs[timeIndex] };

    if (eventType === true) {
      if (updatedTimePair.blockedContent.hasOwnProperty(label)) {
        updatedTimePair.blockedContent[label] = 1;
        updatedTimePairs[timeIndex] = updatedTimePair; // Update the time pair
        updatedSchedule[dayIndex].timePairs = updatedTimePairs;
        setSchedule(updatedSchedule);
      }
    } else if (eventType === false) {
      if (updatedTimePair.blockedContent.hasOwnProperty(label)) {
        updatedTimePair.blockedContent[label] = 0;
        updatedTimePairs[timeIndex] = updatedTimePair; // Update the time pair
        updatedSchedule[dayIndex].timePairs = updatedTimePairs;
        setSchedule(updatedSchedule);
      }
    }
  };
  const doesTimeOverlap = (timePairs: TimePair[]) => {
    const convertToMinutes = (time: Time) =>
      parseInt(time.hour, 10) * 60 + parseInt(time.minute, 10);

    for (let i = 0; i < timePairs.length; i++) {
      const startA = convertToMinutes(timePairs[i].startTime);
      const endA = convertToMinutes(timePairs[i].endTime);

      for (let j = 0; j < timePairs.length; j++) {
        if (i === j) continue; // Skip checking against itself

        const startB = convertToMinutes(timePairs[j].startTime);
        const endB = convertToMinutes(timePairs[j].endTime);

        if (
          (startA < endB && startA >= startB) ||
          (endA > startB && endA <= endB)
        ) {
          return true; // Overlap found
        }
      }
    }

    return false; // No overlap
  };

  const renderTimeOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 15) {
        // Special case for 23:59
        if (i === 23 && j === 45) {
          options.push("23:59");
          break; // Exit the loop after adding 23:59
        }

        const hour = i < 10 ? `0${i}` : `${i}`;
        const minute = j < 10 ? `0${j}` : `${j}`;
        options.push(`${hour}:${minute}`);
      }
    }
    return options.map((time) => (
      <option key={time} value={time}>
        {time}
      </option>
    ));
  };

  const checkDisabled = (usedBy: number, dayIndex: number) => {
    if (usedBy === -1) {
      return false;
    }
    if (usedBy == dayIndex) {
      return false;
    }
    return true;
  };
  const showAddDayButton = () => {
    return daysOfWeek.some((day) => day.usedBy === -1);
  };
  useEffect(() => {
    return () => {
      setDaysOfWeek(initialDays);
      setSchedule([]);
    };
  }, []);
  useEffect(() => {
    // sendDataToParent(schedule);
  }, [schedule]);
  const handleContentPolicyChange = (
    dayIndex: number,
    eventType: boolean,
    label: string
  ) => {
    if (eventType === true) {
      const updatedSchedule: any = [...schedule];
      if (updatedSchedule[dayIndex].blockedContent.hasOwnProperty(label)) {
        updatedSchedule[dayIndex].blockedContent[label] = 1;
        setSchedule(updatedSchedule);
      }
    } else if (eventType === false) {
      const updatedSchedule: any = [...schedule];
      if (updatedSchedule[dayIndex].blockedContent.hasOwnProperty(label)) {
        updatedSchedule[dayIndex].blockedContent[label] = 1;
        setSchedule(updatedSchedule);
      }
    }
  };
  const handleRemoveDay = (dayIndex: number) => {
    const newSchedule = [...schedule];

    // Check if the day exists
    if (newSchedule[dayIndex]) {
      let _updatedDays = initialDays;
      // Remove the day
      newSchedule.splice(dayIndex, 1);
      let _schedule = [...newSchedule];
      _schedule.map((item: DaySchedule, index) => {
        item.days.map((d) => {
          _updatedDays = _updatedDays.map((day) => {
            if (day.dayName === d) {
              return {
                ...day,
                usedBy: index,
              };
            }
            return day;
          });
        });
      });
      setDaysOfWeek(_updatedDays);
      // Update the state with the new array
      setSchedule(newSchedule);
    }
  };
  const handleCreateTimePolicy = () => {
    if (userPolicyName === "") {
      setPolicyNameError("Please Enter User Policy Name");
      return;
    }
    if (schedule.length < 1) {
      openNotification(`error`, "Operation Failed", `Invalid Time Slots`);
      return;
    }
    const slotsToSend: any = [];
    for (const slot of schedule) {
      const doesOverlap = doesTimeOverlap(slot.timePairs);
      if (doesOverlap) {
        openNotification(
          `error`,
          "Operation Failed",
          `Time Overlapping Detected`
        );
        return;
      }
      //
      if (slot.days.length < 1) {
        openNotification(`error`, "Operation Failed", `Invalid Days Selected`);

        return;
      }
      const slotObj: any = {};
      let days = [];
      for (const day of slot.days) {
        const _day = day.toLowerCase();
        const timeSlots = [];
        for (const timePair of slot.timePairs) {
          const startTime = `${
            timePair.startTime.hour !== "" ? timePair.startTime.hour : "00"
          }:${
            timePair.startTime.minute !== "" ? timePair.startTime.minute : "00"
          }`;
          const endTime = `${
            timePair.endTime.hour !== "" ? timePair.endTime.hour : "00"
          }:${timePair.endTime.minute !== "" ? timePair.endTime.minute : "00"}`;
          timeSlots.push({
            startTime,
            endTime,
            blockedContent: timePair.blockedContent,
          });
        }
        days.push({ day: _day, timeSlots });
      }
      slotObj.days = days;
      slotObj.blockedContent = slot.blockedContent;
      slotsToSend.push(slotObj);
    }
    console.log({ slotsToSend });

    const dataToSend: CreateTimePolicyApiProp = {
      userPolicyName: userPolicyName,
      slots: slotsToSend,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiErrorCount, apiErrorCount]);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const { message } = apiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate("/policies");
        }, 4000);
      }
    }
  }, [apiResponse]);

  return (
    <>
      {contextHolder}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className="input-control-container">
            <Input
              style={{ maxWidth: "700px" }}
              className="policy-name-control"
              placeholder="Enter Policy Name"
              value={userPolicyName}
              onChange={(e) => {
                setUserPolicyName(e.target.value);
                setPolicyNameError(null);
              }}
            />
            {policyNameError !== null ? (
              <Typography className="error-message-custom">
                {policyNameError}
              </Typography>
            ) : null}
          </div>

          <div className="mt-20 time-slots-policy-container">
            {/* {error !== null ? <Alert variant="danger">{error}</Alert> : null} */}
            {schedule.map((day, dayIndex) => (
              <Card
                // style={{ border: "" }}
                key={dayIndex}
                className="day-container"
              >
                <div className="cross-icon-container">
                  <img
                    onClick={(e) => {
                      e.preventDefault();
                      handleRemoveDay(dayIndex);
                    }}
                    src={CrossIcon}
                    className="cursor-pointer"
                  />
                </div>
                <div className=" time-slots-main-container">
                  <div className="day-of-weeks my-custom-checkbox-container">
                    {daysOfWeek.map((_day) => {
                      const isUsed = checkDisabled(_day.usedBy, dayIndex);

                      return isUsed === true ? (
                        <></>
                      ) : (
                        <>
                          <label className="" key={_day.dayName}>
                            <input
                              type="checkbox"
                              disabled={checkDisabled(_day.usedBy, dayIndex)}
                              onChange={(e) => {
                                //let's update the used By

                                handleDayChange(
                                  dayIndex,
                                  _day.dayName,
                                  e.target.checked,
                                  _day.id
                                );
                              }}
                              checked={_day.usedBy === dayIndex}
                            />
                            &nbsp;{_day.dayName.substring(0, 3)}
                          </label>
                          &nbsp;
                        </>
                      );
                    })}
                  </div>
                  {/* <div>
                    <span className="router-second-card-field-label">
                      Block Content{" "}
                    </span>
                    {contentPolicies.map((policy) => (
                      <>
                        <label
                          className="content-policy-label"
                          key={policy.value}
                        >
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              //let's update the used By

                              handleContentPolicyChange(
                                dayIndex,
                                e.target.checked,
                                policy.value
                              );
                            }}
                          />
                          &nbsp;{policy.label}
                        </label>
                        &nbsp;
                      </>
                    ))}
                  </div> */}
                  <div style={{ width: "80%" }}>
                    {day.timePairs.map((timePair, timeIndex) => (
                      <div className="single-time-slot-container">
                        <div>
                          <div className="content-parent-section">
                            <div>
                              <Typography className="block-content-label">
                                {" "}
                                Block Content
                              </Typography>
                            </div>
                            <div className="block-content-section">
                              <div>
                                <Typography className="single-content-policy-container">
                                  {timePair.blockedContent.fb === 0 ? (
                                    <>
                                      <img
                                        className="cursor-pointer"
                                        src={UnBlockIcon}
                                        onClick={(e) => {
                                          handleBlockContentChangeInTimeSlot(
                                            dayIndex,
                                            timeIndex,
                                            true,
                                            "fb"
                                          );
                                        }}
                                      />
                                      <span className="span-unblocked">
                                        FaceBook
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        className="cursor-pointer"
                                        src={BlockedIcon}
                                        onClick={(e) => {
                                          handleBlockContentChangeInTimeSlot(
                                            dayIndex,
                                            timeIndex,
                                            false,
                                            "fb"
                                          );
                                        }}
                                      />
                                      <span className="span-blocked">
                                        FaceBook
                                      </span>
                                    </>
                                  )}
                                </Typography>
                                <Typography className="single-content-policy-container">
                                  {timePair.blockedContent.tiktok === 0 ? (
                                    <>
                                      <img
                                        className="cursor-pointer"
                                        src={UnBlockIcon}
                                        onClick={(e) => {
                                          handleBlockContentChangeInTimeSlot(
                                            dayIndex,
                                            timeIndex,
                                            true,
                                            "tiktok"
                                          );
                                        }}
                                      />
                                      <span className="span-unblocked">
                                        Tiktok
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        className="cursor-pointer"
                                        src={BlockedIcon}
                                        onClick={(e) => {
                                          handleBlockContentChangeInTimeSlot(
                                            dayIndex,
                                            timeIndex,
                                            false,
                                            "tiktok"
                                          );
                                        }}
                                      />
                                      <span className="span-blocked">
                                        Tiktok
                                      </span>
                                    </>
                                  )}
                                </Typography>

                                {/* <Typography className="single-content-policy-container">
                                  <img
                                    className="cursor-pointer"
                                    src={UnBlockIcon}
                                  />
                                  <span className="span-unblocked">TikTok</span>
                                </Typography> */}
                              </div>
                              <div>
                                <Typography className="single-content-policy-container">
                                  {timePair.blockedContent.yt === 0 ? (
                                    <>
                                      <img
                                        className="cursor-pointer"
                                        src={UnBlockIcon}
                                        onClick={(e) => {
                                          handleBlockContentChangeInTimeSlot(
                                            dayIndex,
                                            timeIndex,
                                            true,
                                            "yt"
                                          );
                                        }}
                                      />
                                      <span className="span-unblocked">
                                        Youtube
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        className="cursor-pointer"
                                        src={BlockedIcon}
                                        onClick={(e) => {
                                          handleBlockContentChangeInTimeSlot(
                                            dayIndex,
                                            timeIndex,
                                            false,
                                            "yt"
                                          );
                                        }}
                                      />
                                      <span className="span-blocked">
                                        Youtube
                                      </span>
                                    </>
                                  )}
                                </Typography>
                                {/* <Typography className="single-content-policy-container">
                                  <img
                                    className="cursor-pointer"
                                    src={UnBlockIcon}
                                  />
                                  <span className="span-unblocked">
                                    Youtube
                                  </span>
                                </Typography> */}
                                {/* <Typography className="single-content-policy-container">
                                  <img
                                    className="cursor-pointer"
                                    src={UnBlockIcon}
                                  />
                                  <span className="span-unblocked">
                                    Instagram
                                  </span>
                                </Typography> */}
                                <Typography className="single-content-policy-container">
                                  {timePair.blockedContent.ig === 0 ? (
                                    <>
                                      <img
                                        className="cursor-pointer"
                                        src={UnBlockIcon}
                                        onClick={(e) => {
                                          handleBlockContentChangeInTimeSlot(
                                            dayIndex,
                                            timeIndex,
                                            true,
                                            "ig"
                                          );
                                        }}
                                      />
                                      <span className="span-unblocked">
                                        Instagram
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        className="cursor-pointer"
                                        src={BlockedIcon}
                                        onClick={(e) => {
                                          handleBlockContentChangeInTimeSlot(
                                            dayIndex,
                                            timeIndex,
                                            false,
                                            "ig"
                                          );
                                        }}
                                      />
                                      <span className="span-blocked">
                                        Instagram
                                      </span>
                                    </>
                                  )}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          // style={{ display: "flex", justifyContent: "center" }}

                          className="time-pair-container"
                          key={timeIndex}
                        >
                          <div className="time-parent-section">
                            <div>
                              <Typography className="time-slot-content-label">
                                Time Duration
                              </Typography>
                            </div>
                            <div className="time-pair-section">
                              <div className="single-time-entity-container">
                                <Typography className="time-pair-section-label">
                                  Start
                                </Typography>
                                <select
                                  className="custom-select-for-time"
                                  value={`${timePair.startTime.hour}:${timePair.startTime.minute}`}
                                  onChange={(e) => {
                                    const [hour, minute] =
                                      e.target.value.split(":");
                                    handleTimeChange(
                                      dayIndex,
                                      timeIndex,
                                      "startTime",
                                      "hour",
                                      hour
                                    );
                                    handleTimeChange(
                                      dayIndex,
                                      timeIndex,
                                      "startTime",
                                      "minute",
                                      minute
                                    );
                                  }}
                                >
                                  {renderTimeOptions()}
                                </select>
                              </div>

                              <div className="single-time-entity-container">
                                <Typography className="time-pair-section-label">
                                  End
                                </Typography>
                                <select
                                  className="custom-select-for-time"
                                  value={`${timePair.endTime.hour}:${timePair.endTime.minute}`}
                                  onChange={(e) => {
                                    const [hour, minute] =
                                      e.target.value.split(":");
                                    handleTimeChange(
                                      dayIndex,
                                      timeIndex,
                                      "endTime",
                                      "hour",
                                      hour
                                    );
                                    handleTimeChange(
                                      dayIndex,
                                      timeIndex,
                                      "endTime",
                                      "minute",
                                      minute
                                    );
                                  }}
                                >
                                  {renderTimeOptions()}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div
                            onClick={(e) => {
                              //let's write the handle remove function of a time
                              // handleRemove
                              handleRemoveTimePair(dayIndex, timeIndex);
                            }}
                            className="remove-container"
                          >
                            <img src={RemoveIcon} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-20">
                    {day.timePairs.length <= 4 ? (
                      <Button
                        type="primary"
                        onClick={() => handleAddTimePair(dayIndex)}
                      >
                        Add New Time
                      </Button>
                    ) : null}
                  </div>
                </div>
              </Card>
            ))}
            <div className="control-buttons-container">
              {showAddDayButton() === true ? (
                <Button
                  type="primary"
                  className="add-new-day-button"
                  onClick={handleAddDay}
                >
                  Add New Day
                </Button>
              ) : null}
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleCreateTimePolicy();
                }}
                type="primary"
                className="create-time-policy-button"
              >
                Create Time Policy
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default CreatePolicyComponent;
