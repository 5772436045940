import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import { Menu, Layout, Breadcrumb } from "antd";
import type { MenuProps, MenuTheme } from "antd/es/menu";
import Logo from "./../../assets/pngs/logo/Logo.png";
import "./layout.css";
import { AppRoutes } from "../../data";
import { useLocation, useNavigate } from "react-router-dom";
import TopNavBar from "./TopNav";
import PageTitle from "./CurrentPageTitle";
// import MyComponent from "../../MyComponent";
import HomePage from "../../pages/HomePage";
const { Sider, Header, Content } = Layout;
type MenuItem = Required<MenuProps>["items"][number];

const AppLayout = () => {
  const isLoggedIn = true; //localStorage.getItem("auth_token") || false;
  const [items, setItems] = useState<MenuItem[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const routes = AppRoutes();
    const _items = routes
      .filter((item: any) => item.show === true)
      .map((item: any) => {
        return {
          key: item.path,
          icon: <img src={item.icon} />,
          label: item.text,
        };
      });
    setItems(_items);
  }, []);
  const [mode, setMode] = useState<"vertical" | "inline">("inline");
  const [theme, setTheme] = useState<MenuTheme>("light");
  const [routes, setRoutes] = useState<any>([]);
  const [activePageTitle, setActivePageTitle] = useState<string | null>(null);

  useEffect(() => {
    setRoutes(AppRoutes());
  }, []);
  useEffect(() => {
    const routes = AppRoutes();
    const activeRoute = routes.find((item: any) =>
      item.path.startsWith(location.pathname)
    );
    console.log({ activeRoute });
    const currentPageTitle = activeRoute?.text || "Dashboard";
    setActivePageTitle(currentPageTitle);
  }, [location]);
  return (
    <>
      <Layout>
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0px",
            height: "120px",
            background: "white",
          }}
        >
          <div className="top-nav-bar-custom">
            <div className="logo">
              <img src={Logo} alt="Logo" />
              {activePageTitle !== null ? (
                <PageTitle title={activePageTitle} />
              ) : null}
            </div>
            <TopNavBar />
          </div>
        </Header>
        <Layout>
          <Sider
            className="side-bar-main"
            style={{ minHeight: "100vh" }}
            width={200}
          >
            <Menu
              mode="inline"
              selectedKeys={[location.pathname]}
              onClick={(e) => {
                console.log(e.key);
                navigate(e.key);
              }}
              defaultOpenKeys={["sub1"]}
              style={{ height: "100%", borderRight: 0, width: "inherit" }}
              defaultSelectedKeys={["1"]}
              items={items}
              theme={theme}
            />
          </Sider>
          <Layout style={{ padding: "0 24px 24px" }}>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Routes>
                <Route path="/" element={<HomePage />} />
                {isLoggedIn &&
                  routes.map((route: any) => {
                    return (
                      <Route
                        path={route.path}
                        key={Math.random()}
                        element={<route.Component />}
                      />
                    );
                  })}
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default AppLayout;
