import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import CreatePolicyComponent from "../components/policies/CreatePolicyComponent";

const CreatePolicyPage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="Create Policy"
        routes={[
          { title: "Home", path: "/" },
          { title: "Policies", path: "/policies" },
        ]}
      />
      <CreatePolicyComponent />
    </>
  );
};
export default CreatePolicyPage;
