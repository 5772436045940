import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import CreateUserComponent from "../components/users/CreateUserComponent";

const CreateUserPage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="Create User"
        routes={[
          { title: "Home", path: "/" },
          { title: "Users", path: "/users" },
        ]}
      />
      <CreateUserComponent />{" "}
    </>
  );
};
export default CreateUserPage;
