import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Checkbox,
  Input,
  Typography,
  Select,
} from "antd";
import useAxiosPost from "../../hooks/useAxiosPost";
import "./index.css";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
interface SelectOptions {
  value: string;
  label: string;
}
const CreateBatchComponent = () => {
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<BatchCreationProp>("batches/createNewBatch");
  const { openNotification, contextHolder } = useMultiNotification();

  const [allPackages, setAllPackages] = useState<any>([]);
  const [packageOptions, setAllPackageOptions] = useState<SelectOptions[]>([]);
  const [limitEnable, setLimitEnable] = useState<boolean>(false);

  const [
    getPackagesApi,
    packagesApiResponse,
    packagesApiError,
    packagesApiLoading,
    packagesApiMessage,
    packagesApiApiErrorCount,
  ] = useAxiosPost<ResponseProp>("packages/getMyPackages");
  useEffect(() => {
    if (packagesApiResponse !== null) {
      // userType, email
      const responseData: any = packagesApiResponse.responseData;
      console.log({ responseData });
      setAllPackages(responseData || []);
      const res = responseData || [];
      setAllPackageOptions(
        res.map((item: any) => {
          return {
            label: item.packageName,
            value: item.intPkPackageID,
          };
        })
      );
    }
  }, [packagesApiResponse]);
  useEffect(() => {
    getPackagesApi({});
  }, []);
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    console.log("Received values:", values);
    const { batchName, batchLength, packageId } = values;
    if (batchLength < 1) {
      openNotification(
        "error",
        "Validation Error",
        `Invalid Number of vouchers`
      );
      return;
    }
    const dataToSend = {
      batchName,
      batchLength: Number(batchLength),
      voucherCharactersLength: 8,
      intPkPackageID: Number(packageId),
    };
    postApiData(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiErrorCount, apiErrorCount]);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const { message } = apiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate("/batches");
        }, 4000);
      }
    }
  }, [apiResponse]);
  return (
    <>
      {contextHolder}
      <Row className="create-package-container" gutter={[16, 16]}>
        <div className="general-form-container">
          <Typography className="general-form-title">Create Batch</Typography>

          <Form
            // className="login-form"
            name="createBatchForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item
              label={<span className="general-input-label">Name Of Batch</span>}
              name="batchName"
              rules={[
                {
                  required: true,
                  message: "Please Enter Batch Name",
                },
              ]}
              style={{ marginBottom: "8px" }}
              // className="login-form-input-control"
            >
              <Input
                className="general-form-input-item"
                placeholder="Batch Name"
              />
            </Form.Item>
            <Form.Item
              label={
                <span className="general-input-label">Number Of Vouchers</span>
              }
              name="batchLength"
              rules={[
                {
                  required: true,
                  message: "Please enter Number of Vouchers",
                },
              ]}
              style={{ marginBottom: "8px" }}
              // className="login-form-input-control"
            >
              <Input
                className="general-form-input-item"
                placeholder="Number Of Vouchers"
                type="number"
              />
            </Form.Item>
            <Form.Item
              label={
                <span className="general-input-label">Select Package</span>
              }
              name="packageId"
              rules={[
                {
                  required: true,
                  message: "Please Choose A Package ",
                },
              ]}
              style={{ marginBottom: "8px", color: "blue" }}
            >
              <Select
                className="package-form-select-item"
                showSearch
                placeholder="Select Package"
                optionFilterProp="children"
                filterOption={filterOption}
                options={packageOptions}
              />
            </Form.Item>

            <Form.Item>
              <Button
                className="login-submit-button"
                type="primary"
                htmlType="submit"
              >
                Create Batch
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Row>
    </>
  );
};
export default CreateBatchComponent;
