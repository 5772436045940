import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import EditProfileComponent from "../components/auth/profile/EditProfileComponent";

const EditProfilePage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="Update Profile"
        routes={[
          { title: "Home", path: "/" },
          { title: "Profile", path: "/profile" },
        ]}
      />
      <EditProfileComponent />
    </>
  );
};
export default EditProfilePage;
