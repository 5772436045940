import { useEffect, useState } from "react";
import { Row, Col, Button, Card } from "antd";
import PackageCard from "../Items/Cards/package/PackageCard";
import useAxiosPost from "../../hooks/useAxiosPost";
import { formatDate } from "../../helpers/date";
import "./index.css";
import { useNavigate } from "react-router-dom";
import UserPolicyCard from "../Items/Cards/policy/UserPolicyCard";
const ViewPoliciesComponent = () => {
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("policies/getMyPolicies");
  const [userPolicies, setAllUserPolicies] = useState<any>([]);
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      setAllUserPolicies(apiResponse.responseData || []);
    }
  }, [apiResponse]);
  return (
    <>
      <Row
        gutter={16}
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigate("/create-policy");
          }}
          type="primary"
          className="login-submit-button"
        >
          Create Policy
        </Button>
      </Row>
      <Row gutter={[16, 16]}>
        {userPolicies.map((item: any) => {
          return (
            <Col span={8}>
              <UserPolicyCard data={item} />
            </Col>
          );
        })}
      </Row>
    </>
  );
};
export default ViewPoliciesComponent;
