import LoginComponent from "../components/auth/LoginComponent";

const LoginPage = () => {
  return (
    <>
      <LoginComponent />
    </>
  );
};
export default LoginPage;
