import React from "react";
import "./index.css";
import { Space, Table, Tag, Typography, Badge, Button } from "antd";
import type { ColumnsType } from "antd/es/table";

import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import OnlineStatusIndicator from "../Status/OnlineStatusIndicator";
import { useNavigate } from "react-router-dom";
import ThreeDotsOptionsMenuIcon from "./../../../assets/svgs/OptionsMenuIcon/ThreeDotsOptionsMenuIcon.svg";

const UsersTable: React.FC<UsersTableProp> = ({ users, isActionButton }) => {
  const navigate = useNavigate();
  const columns: ColumnsType<UsersInfoTableDataType> = [
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      render: (_, { userName, onlineStatus, intPkClientID }) => (
        <>
          <Typography
            className="cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/user-details/${intPkClientID}`);
            }}
          >
            <OnlineStatusIndicator isOnline={Boolean(onlineStatus)} />
            {userName}
          </Typography>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <>
          <Badge count={`${status}`} className={`user-status-${status}`} />
        </>
      ),
    },
    {
      title: "Package Name",
      dataIndex: "packageName",
      key: "packageName",
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
    },
    {
      title: "Action",
      key: "action",
      render: (_, { onlineStatus }) => {
        return (
          <>
            {isActionButton === true ? (
              <>
                <img
                  className="cursor-pointer"
                  src={ThreeDotsOptionsMenuIcon}
                />
              </>
            ) : onlineStatus === true ? (
              <Button type="primary" danger>
                Kick
              </Button>
            ) : (
              <Button disabled>Kick</Button>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="table-container-custom">
        <Table
          className="antd-table-striped"
          columns={columns}
          dataSource={users}
          pagination={false}
        />
      </div>
    </>
  );
};

export default UsersTable;
