import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Button, Checkbox } from "antd";
import "./styles/Login.css";
import ForgotPasswordImage from "./../../assets/pngs/auth/ForgotPasswordImage.png";
import LogoImage from "./../../assets/pngs/logo/Logo.png";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
const ForgotPasswordComponent = () => {
  const navigate = useNavigate();
  const { openNotification, contextHolder } = useMultiNotification();
  const [
    postLoginData,
    loginResponseData,
    loginError,
    loginLoading,
    loginMessage,
    loginErrorCount,
  ] = useAxiosPost<ForgotPasswordType>("auth/forgotPassword");
  const [email, setEmail] = useState<string>("");
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    console.log("Received values:", values);
    const { email } = values;
    console.log({ email });
    const dataToSend: ForgotPasswordType = {
      email: email,
    };
    setEmail(email);
    console.log({ dataToSend });
    postLoginData(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (loginError !== null && loginErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", loginError);
    }
  }, [loginError, loginErrorCount]);
  useEffect(() => {
    if (loginResponseData !== null) {
      sessionStorage.setItem("email", email);
      window.location.href = "/forgot-password-otp";
    }
  }, [loginResponseData]);
  return (
    <>
      {contextHolder}
      <div className="login-page-background">
        <div className="login-container">
          <div className="login-items">
            <Row gutter={[16, 1]} style={{ height: "100%" }}>
              <Col span={12}>
                <div className="login-form-right-container">
                  <div className="logo-login-form">
                    <img src={LogoImage} alt="Logo Image" />
                  </div>
                  <Typography className="login-form-title">
                    FORGOT PASSWORD?
                  </Typography>
                  <Typography className="login-form-description">
                    We will help you change your password, Please provide us
                    email address you provided when sign up
                  </Typography>
                  <Form
                    className="login-form"
                    name="emailForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    requiredMark={false}
                  >
                    <Form.Item
                      label={
                        <span className="login-form-input-label">Email</span>
                      }
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email address",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid email address",
                        },
                      ]}
                      style={{ marginBottom: "8px", color: "blue" }}
                      className="login-form-input-control"
                    >
                      <Input
                        className="login-form-input-item"
                        placeholder="Enter your email"
                      />
                    </Form.Item>

                    <div className="remember-me-forgot-password-container">
                      <div className="remember-me-container"></div>
                      <div className="forgot-password-container">
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/login");
                          }}
                          className="forgot-password-label"
                        >
                          Login
                        </span>
                      </div>
                    </div>

                    <Form.Item>
                      <Button
                        className="login-submit-button"
                        type="primary"
                        htmlType="submit"
                      >
                        Recover Password
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
              <Col span={12}>
                <div className="login-right-container">
                  <img src={ForgotPasswordImage} alt="FORGOT PASSWORD Image" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgotPasswordComponent;
