import OTPInputComponent from "../components/auth/OTPInput";

const OTPInputPage = () => {
  return (
    <>
      <OTPInputComponent />
    </>
  );
};
export default OTPInputPage;
