import React from "react";
import BackButtonIcon from "./../../assets/svgs/BackButton.svg";
import { useNavigate } from "react-router-dom";
interface PageTitleProp {
  title: string;
}
const PageTitle: React.FC<PageTitleProp> = ({ title }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="active-page-header-container">
        <div>
          <span>
            <img
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              style={{ marginTop: "18px", cursor: "pointer" }}
              src={BackButtonIcon}
            />
          </span>
        </div>
        <div className="current-active-page-title">{title}</div>
      </div>
    </>
  );
};

export default PageTitle;
