import { Typography, Card, Divider, Dropdown, Row, Col } from "antd";
import "./singleSiteCard.css";
import LinkIcon from "./../../../../assets/svgs/linkIcon/LinkIcon.svg";
import MenuIcon from "./../../../../assets/svgs/OptionsMenuIcon/MenuIcon.svg";
import OnlineStatusIndicator from "../../Status/OnlineStatusIndicator";
import RefreshButton from "./../../../../assets/svgs/IconsWhite/RefreshButton.svg";
import UsersIcon from "./../../../../assets/svgs/IconsWhite/UsersWhiteIcon.svg";
import type { MenuProps } from "antd";

const SingleSiteMidCard: React.FC<SingleSiteMidCardProp> = ({
  title,
  value,
  icon,
  cardClassName,
}) => {
  return (
    <div className={`single-site-mid-card ${cardClassName}`}>
      <div className="mid-card-title-container">
        <img src={icon} />
        <Typography className="mid-card-box-title">{title}</Typography>
      </div>
      <div className="mid-card-box-value-container">
        <Typography className="mid-card-box-value">{value}</Typography>
      </div>
    </div>
  );
};

const RouterSingleRow: React.FC<SingleRowRouterDetailsProp> = ({
  title,
  value,
  valueSpecialClass,
}) => {
  return (
    <Row gutter={[6, 16]}>
      <Col span={4}>
        <div className="router-details-label-container">
          <Typography className="router-details-label">{title}</Typography>
          <Typography className="router-details-label">:</Typography>
        </div>
      </Col>
      <Col span={20}>
        <Typography className={`router-details-value ${valueSpecialClass}`}>
          {value}
        </Typography>
      </Col>
    </Row>
  );
};
const SingleSiteCard: React.FC<SingleSiteCardProp> = ({
  enable,
  intPkCompanyID,
  intPkCompanySiteID,
  siteAddress,
  siteName,
  routerDetails,
}) => {
  const onClick: MenuProps["onClick"] = ({ key }) => {
    console.log("Hi", key);
  };
  const items: MenuProps["items"] = [
    {
      key: "siteDetails",
      label: <span>Site Details</span>,
    },

    {
      key: "refresh",
      label: <span>Refresh</span>,
    },
  ];

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Card className="border-card">
          <div className="site-card-top-items">
            <div className="site-details-box">
              <div className="site-title-box">
                <Typography className="site-header-title">
                  {siteName}
                </Typography>
                <img className="cursor-pointer" src={LinkIcon} />
              </div>
              <div>
                <Typography className="site-header-address">
                  {siteAddress}
                </Typography>
              </div>
            </div>
            <div className="site-status-indicator-box">
              <OnlineStatusIndicator isOnline={true} /> ON
            </div>
            <div className="site-options-opener-container">
              <Dropdown
                menu={{ items, onClick }}
                trigger={["click"]}
                placement="bottomRight"
                arrow={{ pointAtCenter: true }}
              >
                <img className="cursor-pointer" src={MenuIcon} />
              </Dropdown>
            </div>
          </div>
          <Divider
            style={{ borderStyle: "dashed", borderWidth: 1, color: "#DCDCDC" }}
          />

          {/* First Section End */}

          <div className="site-card-package-details">
            <Typography className="site-package-label">Package</Typography>
            <Typography className="site-package-title">
              Platinum Grace
            </Typography>
          </div>
        </Card>
      </Col>
      {/* Second Card */}
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <SingleSiteMidCard
              icon={UsersIcon}
              title="Online Users"
              value={"80"}
              cardClassName="mid-card-1"
            />
            {/* <div className="single-site-mid-card mid-card-1"></div> */}
          </Col>
          <Col span={8}>
            {/* <div className="single-site-mid-card mid-card-2"></div> */}
            <SingleSiteMidCard
              icon={UsersIcon}
              title="Offline Users"
              value={"50"}
              cardClassName="mid-card-2"
            />
          </Col>
          <Col span={8}>
            {" "}
            {/* <div className="single-site-mid-card mid-card-3"></div> */}
            <SingleSiteMidCard
              icon={UsersIcon}
              title="All Users"
              value={"150"}
              cardClassName="mid-card-3"
            />
          </Col>
        </Row>
      </Col>
      {/* Second Card End */}
      <Col span={24}>
        <Typography className="single-site-card-router-label">
          Router
        </Typography>
      </Col>
      {routerDetails !== null ? (
        <>
          <Col span={20}>
            <Card className="border-card" style={{ minHeight: "150px" }}>
              <RouterSingleRow title="SSID" value={routerDetails.routerName} />
              <RouterSingleRow title="Device" value={`Mikrotik`} />
              <RouterSingleRow
                title="Link"
                value={routerDetails.eUserLocalNetworkLinkLogin}
                valueSpecialClass="link-class"
              />
              <RouterSingleRow title="IP" value={routerDetails.coreRouterIp} />
              <RouterSingleRow
                title="Vendor"
                value={routerDetails.coreRouterVendorName}
              />
            </Card>
          </Col>
          <Col span={4}>
            <div className="router-restart-button-container">
              <div>
                <Typography className="restart-router-label">
                  Restart Router
                </Typography>
              </div>
              <div>
                <img className="cursor-pointer" src={RefreshButton} />
              </div>
            </div>
          </Col>
        </>
      ) : null}
    </Row>
  );
};
export default SingleSiteCard;
