import ResetPasswordComponent from "../components/auth/ResetPasswordComponent";

const ResetPasswordPage = () => {
  return (
    <>
      <ResetPasswordComponent />
    </>
  );
};
export default ResetPasswordPage;
