import { useEffect, useState } from "react";
import { Row, Col, Card, Typography, Button } from "antd";
import "./index.css";
import SitesIcon from "./../../../assets/svgs/MenuIcons/SitesIcon.svg";
import UsersIcon from "./../../../assets/svgs/MenuIcons/UsersIcon.svg";
import PackagesIcon from "./../../../assets/svgs/MenuIcons/PackagesIcon.svg";
import VoucherIcon from "./../../../assets/svgs/MenuIcons/VoucherIcon.svg";
import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../../hooks/useAxiosPost";
interface UserProfileBoxProp {
  icon: any;
  title: string;
  value: string;
}
const UserProfileBoxItem: React.FC<UserProfileBoxProp> = ({
  icon,
  title,
  value,
}) => {
  return (
    <Card className="border-card ">
      <div className="single-item-user-profile">
        <div>
          <img src={icon} />
        </div>
        <div>
          <Typography className="user-profile-single-item-label">
            {title}
          </Typography>
          <Typography className="user-profile-single-item-value">
            {value}
          </Typography>
        </div>
      </div>
    </Card>
  );
};
const MyProfileComponent = () => {
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("auth/getProfile");
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const data: any = apiResponse.responseData;
      setProfileData(data);
    }
  }, [apiResponse]);

  const [profileData, setProfileData] = useState<any>({});
  useEffect(() => {
    postApiData({});
  }, []);
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase();
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className="profile-container">
            <div className="user-profile-icon">
              {/* <img src={ProfileBG} /> */}
              <Typography className="user-profile-initial">
                {capitalizeFirstLetter(profileData.fullName || "a")}
              </Typography>
            </div>
          </div>
          <Card className="border-card user-profile-details-container">
            <div style={{ marginLeft: "-10px" }}>
              <div className="user-full-name-container">
                <Typography className="user-profile-name">
                  {profileData.fullName}
                </Typography>
              </div>
              <div className="">
                <div className="user-profile-details-row">
                  <Typography className="user-profile-details-label">
                    Name
                  </Typography>
                  <Typography className="user-profile-details-value">
                    {profileData.fullName}
                  </Typography>
                </div>
                <div className="user-profile-details-row">
                  <Typography className="user-profile-details-label">
                    Email
                  </Typography>
                  <Typography className="user-profile-details-value">
                    {profileData.email}
                  </Typography>
                </div>
                <div className="user-profile-details-row">
                  <Typography className="user-profile-details-label">
                    Contact
                  </Typography>
                  <Typography className="user-profile-details-value">
                    {profileData.contactNumber}
                  </Typography>
                </div>
              </div>
            </div>
          </Card>
          <Row gutter={[36, 36]} style={{ marginTop: "30px" }}>
            <Col span={6}>
              <UserProfileBoxItem
                title="Sites"
                value={profileData.sites}
                icon={SitesIcon}
              />
            </Col>
            <Col span={6}>
              <UserProfileBoxItem
                title="Users"
                value={profileData.users}
                icon={UsersIcon}
              />
            </Col>
            <Col span={6}>
              <UserProfileBoxItem
                title="Packages"
                value={profileData.packages}
                icon={PackagesIcon}
              />
            </Col>
            <Col span={6}>
              <UserProfileBoxItem
                title="Vouchers"
                // value="370"
                value={profileData.vouchers}
                icon={VoucherIcon}
              />
            </Col>
          </Row>
          <Row gutter={[36, 36]} style={{ marginTop: "30px" }}>
            <Col span={24}>
              <div className="bottom-buttons-user-profile">
                <Button
                  className="edit-profile-button"
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();

                    navigate("/edit-profile");
                  }}
                >
                  Edit Profile
                </Button>
                <Button
                  className="change-password-button"
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/change-password`);
                  }}
                >
                  Change Password
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default MyProfileComponent;
