import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Card, Select } from "antd";
import UsersTable from "../Items/Table/UsersTable";
import SplineAreaChart from "../Charts/SplineAreaChart";
import UsersIcon from "./../../assets/svgs/IconsBlack/UsersIconBlack.svg";
import SitesIcon from "./../../assets/svgs/IconsBlack/SitesIconBlack.svg";
import type { SelectProps } from "antd";
import DashboardBoxItem from "../Items/Box/DashboardBoxItem";
import useAxiosPost from "../../hooks/useAxiosPost";
import { formatDateForJustMonthDayAndYear } from "../../helpers/date";

const DashboardComponent = () => {
  const options: SelectProps["options"] = [
    {
      label: "Hamza Arshad",
      value: "1",
    },
  ];
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("clients/getClientUsers");
  const [users, setUsers] = useState<UsersInfoTableDataType[]>([]);

  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const responseData: any = apiResponse.responseData || [];
      setUsers(
        responseData.map((item: any): UsersInfoTableDataType => {
          return {
            userName: item.clientName,
            status: item.clientStatus,
            packageName: "2MB30GB",
            expiryDate: item.expiryDate
              ? formatDateForJustMonthDayAndYear(item.expiryDate).toString()
              : "-",
            intPkAuthenticatedDeviceID: item.intPkAuthenticatedDeviceID || null,
            intPkClientID: Number(item.intPkClientID),
            onlineStatus: item.onlineStatus,
          };
        })
      );
    }
  }, [apiResponse]);

  return (
    <>
      {/* <h1>Dashboard</h1> */}

      <Row gutter={16}>
        <Col span={16}>
          <SplineAreaChart />
        </Col>
        <Col span={8}>
          <Select
            size={"large"}
            defaultValue="Hamza Arshad"
            //   onChange={handleChange}
            style={{ width: "100%" }}
            options={options}
          />
          <Row
            style={{
              marginTop: "10px",
            }}
            gutter={[16, 8]}
          >
            <Col span={12}>
              <DashboardBoxItem
                icon={UsersIcon}
                counterValue={80}
                title="Online Users"
              />
            </Col>
            <Col span={12}>
              <DashboardBoxItem
                icon={UsersIcon}
                counterValue={120}
                title="User Capacity"
              />
            </Col>
            <Col span={12}>
              <DashboardBoxItem
                icon={UsersIcon}
                counterValue={46}
                title="Total Users"
              />
            </Col>
            <Col span={12}>
              <DashboardBoxItem
                icon={SitesIcon}
                counterValue={4}
                title="Sites"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <UsersTable users={users} isActionButton={false} />
        </Col>
      </Row>
    </>
  );
};
export default DashboardComponent;
