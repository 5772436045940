import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Card, Select, Button } from "antd";
import type { SelectProps } from "antd";
import "./index.css";
import BatchCard from "../Items/Cards/batch/BatchCard";
import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import {
  formatDate,
  formatDateForJustMonthDayAndYear,
} from "../../helpers/date";
const BatchesComponent = () => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("batches/getAllBatchesForCompany");
  const navigate = useNavigate();
  const [allBatches, setAllBatches] = useState<any>([]);
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const responseData: any = apiResponse.responseData;
      console.log({ responseData });
      setAllBatches(responseData || []);
    }
  }, [apiResponse]);
  const getSpeed = (speed: string, rxTxSpeedUnit: string) => {
    return `${speed}${getSpeedUnit(rxTxSpeedUnit)}`;
  };
  const getSpeedUnit = (unit: string) => {
    if (unit === "G") {
      return "GB";
    }
    if (unit === "M") {
      return "MB";
    }
    return "Kb";
  };
  const showVolume = (maxDataVolumeLimit: any, maxDataVolumeUnit: any) => {
    if (
      maxDataVolumeUnit === null ||
      maxDataVolumeLimit == null ||
      maxDataVolumeLimit == "null" ||
      maxDataVolumeUnit == "null"
    ) {
      return "Unlimited";
    }
    return `${maxDataVolumeLimit}${getSpeedUnit(maxDataVolumeUnit)}`;
  };
  return (
    <>
      <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
        <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/create-batch`);
            }}
            className="fetch-devices-button"
            style={{ width: "200px" }}
          >
            Create Batch
          </Button>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        {allBatches.map((item: any) => {
          const _dateCreated = formatDateForJustMonthDayAndYear(
            new Date(item.dateCreated)
          ).toString();
          const volume = showVolume(
            item.maxDataVolumeLimit,
            item.maxDataVolumeUnit
          );
          return (
            <Col span={8}>
              <BatchCard
                tariffName={item.tariffName}
                batchName={item.batchName}
                isDisabled={!Boolean(Number(item.enable))}
                intPkBatchID={Number(item.intPkBatchID)}
                batchLength={Number(item.batchLength)}
                dateCreated={_dateCreated}
                speed={getSpeed(item.uploadSpeed, item.rxTxSpeedUnit)}
                dataVolume={volume}
                usedVouchers={item?.batchStats?.totalUsedVouchers || 0}
                remainingVouchers={item?.batchStats?.totalUnusedVouchers || 0}
                batchStatus={item.batchStatus}
              />
            </Col>
          );
        })}
        {/* <Col span={8}>
          <BatchCard isDisabled={false} />
        </Col>
        <Col span={8}>
          <BatchCard isDisabled={false} />
        </Col>
        <Col span={8}>
          <BatchCard isDisabled={true} />
        </Col>
        <Col span={8}>
          <BatchCard isDisabled={false} />
        </Col>
        <Col span={8}>
          <BatchCard isDisabled={false} />
        </Col> */}
      </Row>
    </>
  );
};
export default BatchesComponent;
