import { Typography, Card, Divider, Dropdown, Col, Row } from "antd";
import "./policyCard.css";
import MenuIcon from "./../../../../assets/svgs/OptionsMenuIcon/MenuIcon.svg";
import TimePairArrow from "./../../../../assets/pngs/TimePairArrowRight.png";
import UnBlockIcon from "./../../../../assets/pngs/UnBlocked.png";
import BlockedIcon from "./../../../../assets/pngs/Blocked.png";
import type { MenuProps } from "antd";
interface SingleDayTabProp {
  dayName: string;
}
interface SingleTimePairViewProp {
  startTime: string;
  endTime: string;
}
interface SingleTimePairBlockedContentViewProp {
  ig: number;
  tiktok: number;
  yt: number;
  fb: number;
  blockedType: number;
}
const SingleDayTab: React.FC<SingleDayTabProp> = ({ dayName }) => {
  return (
    <Typography className="single-day-tab-container">{dayName}</Typography>
  );
};
const SingleTimePairView: React.FC<SingleTimePairViewProp> = ({
  startTime,
  endTime,
}) => {
  return (
    <div className="single-time-pair-view-card">
      <div>{startTime}</div>
      <img src={TimePairArrow} />
      <div>{endTime}</div>
    </div>
  );
};
const SingleBlockedContentView: React.FC<
  SingleTimePairBlockedContentViewProp
> = ({ fb, yt, ig, tiktok, blockedType }) => {
  const getContainerClassName = (): string => {
    let _className;
    switch (blockedType) {
      case 0:
        _className = "partial-blocked-view";
        break;
      case 1:
        _className = "all-blocked-view";
        break;
      case -1:
        _className = "all-unblocked-view";
        break;
      default:
        _className = "partial-blocked-view";
    }
    return _className;
  };
  return (
    <div
      className={`blocked-content-view-container ${getContainerClassName()}`}
    >
      <div className="content-policy-single-row">
        <Typography className="content-policy-single-entity-container">
          <img
            className="cursor-pointer"
            src={yt === 1 ? BlockedIcon : UnBlockIcon}
          />
          <span className={`${yt === 1 ? "span-blocked" : "span-unblocked"}`}>
            Youtube
          </span>
        </Typography>

        <Typography className="content-policy-single-entity-container">
          <img
            className="cursor-pointer"
            src={fb === 1 ? BlockedIcon : UnBlockIcon}
          />
          <span className={`${fb === 1 ? "span-blocked" : "span-unblocked"}`}>
            Facebook
          </span>
        </Typography>
      </div>
      <div className="content-policy-single-row">
        <Typography className="content-policy-single-entity-container">
          <img
            className="cursor-pointer"
            src={ig === 1 ? BlockedIcon : UnBlockIcon}
          />
          <span className={`${ig === 1 ? "span-blocked" : "span-unblocked"}`}>
            Instagram
          </span>
        </Typography>
        <Typography className="content-policy-single-entity-container">
          <img
            className="cursor-pointer"
            src={tiktok === 1 ? BlockedIcon : UnBlockIcon}
          />
          <span
            className={`${tiktok === 1 ? "span-blocked" : "span-unblocked"}`}
          >
            Tiktok
          </span>
        </Typography>
      </div>
    </div>
  );
};
interface PolicyCardProp {
  data: any;
}
const UserPolicyCard: React.FC<PolicyCardProp> = ({ data }) => {
  const onClick: MenuProps["onClick"] = ({ key }) => {
    console.log("Hi", key);
  };
  const items: MenuProps["items"] = [
    {
      key: "siteDetails",
      label: <span className="drop-down-item-custom">Site Details</span>,
    },

    {
      key: "refresh",
      label: <span className="drop-down-item-custom">Refresh</span>,
    },
  ];

  return (
    <Card className="border-card">
      <div className="user-policy-header">
        <Typography className="user-policy-title">
          {data.userPolicyName}
        </Typography>

        <div className="policy-options-opener-container">
          <Dropdown
            className="custom-drop-down"
            menu={{ items, onClick }}
            trigger={["click"]}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <img className="cursor-pointer" src={MenuIcon} />
          </Dropdown>
        </div>
      </div>
      {data.daySlots &&
        data.daySlots.map((item: any) => {
          return (
            <Card key={Math.random()} className="days-single-row-container">
              <div className="policy-day-names-container">
                {item.days &&
                  item.days.map((_day: string) => {
                    return (
                      <SingleDayTab
                        key={Math.random()}
                        dayName={_day.substring(0, 3)}
                      />
                    );
                  })}
              </div>

              {item.timePairs &&
                item.timePairs.map((timePair: any) => {
                  return (
                    <div
                      key={Math.random()}
                      className="single-container-time-pair-blocked-content"
                    >
                      <SingleTimePairView
                        key={Math.random()}
                        startTime={timePair.startTime}
                        endTime={timePair.endTime}
                      />
                      <SingleBlockedContentView
                        ig={Number(timePair.ig)}
                        tiktok={Number(timePair.tiktok)}
                        fb={Number(timePair.fb)}
                        yt={Number(timePair.yt)}
                        blockedType={Number(timePair.blockedType)}
                      />
                    </div>
                  );
                })}
            </Card>
          );
        })}
    </Card>
  );
};
export default UserPolicyCard;
