import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import DevicesComponent from "../components/devices/DevicesComponent";

const DevicesPage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="Devices"
        routes={[{ title: "Home", path: "/" }]}
      />
      <DevicesComponent />
    </>
  );
};
export default DevicesPage;
