import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import MyProfileComponent from "../components/auth/profile/MyProfileComponent";

const MyProfilePage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="My Profile"
        routes={[{ title: "Home", path: "/" }]}
      />
      <MyProfileComponent />
    </>
  );
};
export default MyProfilePage;
