import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "./chart.css";

const GradientSplineAreaChart: React.FC = () => {
  const options: ApexOptions = {
    chart: {
      type: "area",
      background: "#3A77BC",
      foreColor: "#fff",
    },
    dataLabels: {
      enabled: false,
    },
    // axisTicks: {
    //   show: true,
    // },
    // axisBorder: {
    //   show: true,
    // },
    // grid: {
    //   show: true, // Show the grid lines
    // },
    stroke: {
      curve: "straight",
      colors: ["rgba(255, 255, 255, 0.6)", "rgba(180, 211, 52, 0.6)"], // Adjusted opacity for stroke colors
    },
    xaxis: {
      type: "datetime",
      categories: [
        "2023-01-01",
        "2023-01-02",
        "2023-01-03",
        "2023-01-04",
        "2023-01-05",
        "2023-01-06",
        "2023-01-07",
      ],
    },

    tooltip: {
      x: {
        format: "dd/MM/yy",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.5, // Reduced opacity at start
        opacityTo: 0.3, // Very low opacity at the end
        stops: [0, 100],
      },
    },
    colors: ["#FFFFFF", "#B4D334"], // Second color for the 'Upload' series
  };

  const series = [
    {
      name: "Download",
      data: [31, 40, 28, 51, 42, 109, 100],
    },
    // {
    //   name: "Series 2",
    //   data: [11, 32, 45, 32, 34, 52, 41],
    // },
    {
      name: "Upload",
      data: [15, 11, 32, 18, 29, 23, 34],
    },
  ];

  return (
    <div>
      <Chart options={options} series={series} type="area" height={360} />
    </div>
  );
};

export default GradientSplineAreaChart;
