import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import SingleSiteComponent from "../components/sites/SingleSiteComponent";

const SingleSitePage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="Site Details"
        routes={[
          { title: "Home", path: "/" },
          { title: "Sites", path: "/sites" },
        ]}
      />
      <SingleSiteComponent />
    </>
  );
};
export default SingleSitePage;
