import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import CreateBatchComponent from "../components/batches/CreateBatchComponent";

const CreateBatchPage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="Create Batch"
        routes={[
          { title: "Home", path: "/" },
          { title: "Batches", path: "/batches" },
        ]}
      />
      <CreateBatchComponent />
    </>
  );
};
export default CreateBatchPage;
