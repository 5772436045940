import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import CreatePackageComponent from "../components/packages/CreatePackageComponent";

const CreatePackagePage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="Create Package"
        routes={[
          { title: "Home", path: "/" },
          { title: "Packages", path: "/packages" },
        ]}
      />
      <CreatePackageComponent />
    </>
  );
};
export default CreatePackagePage;
