import { useState } from "react";
import "./Switch.css";
interface UsersSwitch {
  sendActiveTab: (value: string) => any;
}
const SwitchComponent: React.FC<UsersSwitch> = ({ sendActiveTab }) => {
  return (
    <div className="switch-container">
      <div className="switch-wrapper">
        <input
          id="monthly"
          type="radio"
          name="switch"
          value={`online-users`}
          onClick={(e) => {
            console.log("hello");
            sendActiveTab("online-users");
          }}
        />
        <input
          id="yearly"
          type="radio"
          name="switch"
          value={`router-users`}
          onClick={(e) => {
            sendActiveTab("router-users");

            console.log("Hi");
          }}
        />
        <label htmlFor="monthly">Online Users</label>
        <label htmlFor="yearly">Router Users</label>
        <span className="highlighter"></span>
      </div>
    </div>
  );
};
export default SwitchComponent;
