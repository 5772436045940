import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import ChangePasswordComponent from "../components/auth/profile/ChangePasswordComponent";
const ChangePasswordPage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="Change Password"
        routes={[
          { title: "Home", path: "/" },
          { title: "Profile", path: "/profile" },
        ]}
      />
      <ChangePasswordComponent />
    </>
  );
};
export default ChangePasswordPage;
