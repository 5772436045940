import { useEffect, useRef, useState } from "react";
import { Row, Col, Card, Typography, Button, Form, Input } from "antd";
import "./index.css";
import UpdateProfileBG from "./../../../assets/pngs/UpdateProfileBG.png";
import useAxiosPost from "../../../hooks/useAxiosPost";
import useMultiNotification from "../../../hooks/useNotification";

const EditProfileComponent = () => {
  const { openNotification, contextHolder } = useMultiNotification();

  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const formRef = useRef<any>(null);
  const [
    postUpdateProfileDataApi,
    ProfileDataAApiResponse,
    ProfileDataAApiError,
    ProfileDataAApiLoading,
    ProfileDataAApiMessage,
    ProfileDataAApiErrorCount,
  ] = useAxiosPost<UpdateMyProfileProp>("auth/updateProfile");
  useEffect(() => {
    if (ProfileDataAApiError !== null && ProfileDataAApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", ProfileDataAApiError);
    }
  }, [ProfileDataAApiError, ProfileDataAApiErrorCount]);
  useEffect(() => {
    if (ProfileDataAApiResponse !== null) {
      // userType, email
      const { message } = ProfileDataAApiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
      }
    }
  }, [ProfileDataAApiResponse]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("auth/getProfile");
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const data: any = apiResponse.responseData;
      setProfileData(data);
      setProfileData({
        email: data.email,
        contactNumber: data.contactNumber,
        name: data.fullName,
      });
      const dataToSet = {
        email: data.email,
        contactNumber: data.contactNumber,
        name: data.fullName,
      };
      //  setData(fetchedData);
      console.log({ dataToSet });
      if (formRef.current) {
        formRef.current.setFieldsValue(dataToSet);
      }
      // setEmail(data.email);
    }
  }, [apiResponse]);

  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    contactNumber: "",
  });
  useEffect(() => {
    postApiData({});
  }, []);
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    console.log("Received values:", values);
    const { email, contactNumber, name } = values;

    const dataToSend = {
      fullName: name,
      email: email,
      contactNumber: contactNumber,
    };
    postUpdateProfileDataApi(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      {contextHolder}

      {console.log({ profileData })}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className="profile-container">
            <div className="user-profile-icon">
              {/* <img src={ProfileBG} /> */}
              <Typography className="user-profile-initial">H</Typography>
            </div>
          </div>
          <Card className="border-card ">
            <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
              <Col span={12}>
                <Typography className="update-profile-heading">
                  {" "}
                  UPDATE PROFILE
                </Typography>
                <Typography className="update-profile-description">
                  You can update your profile if you have changed your phone
                  number, or email address or your name was not displayed
                  properly
                </Typography>
                <Form
                  // className="login-form"
                  // name="emailForm"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  requiredMark={false}
                  // initialValues={profileData}
                  form={form}
                  ref={formRef}
                  initialValues={profileData}
                >
                  <Form.Item
                    label={
                      <span className="update-profile-form-input-label">
                        Name
                      </span>
                    }
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Full Name",
                      },
                      {
                        min: 4,
                        message: "Name should be at least 4 characters long",
                      },
                    ]}
                    style={{ marginBottom: "8px", color: "blue" }}
                    className="login-form-input-control"
                  >
                    <Input
                      className="update-profile-input-item"
                      placeholder="Enter your Name"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span className="update-profile-form-input-label">
                        Email
                      </span>
                    }
                    name="email"
                    style={{ marginBottom: "8px", color: "blue" }}
                    className="login-form-input-control"
                  >
                    <Input
                      className="update-profile-input-item"
                      placeholder="Enter your email"
                      disabled
                      // onChange={(e) => {
                      //   console.log(e.target.value);
                      //   setEmail(e.target.value);
                      // }}
                      // value={email}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span className="update-profile-form-input-label">
                        Phone Number
                      </span>
                    }
                    name="contactNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Phone Number",
                      },
                      // {
                      //   min: 4,
                      //   message: "Name should be at least 4 characters long",
                      // },
                    ]}
                    style={{ marginBottom: "8px", color: "blue" }}
                    className="login-form-input-control"
                  >
                    <Input
                      className="update-profile-input-item"
                      placeholder="Enter your Phone"
                      disabled
                      // value={profileData.contactNumber}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="change-password-button"
                      type="primary"
                      htmlType="submit"
                    >
                      Update Profile
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={12}>
                <div className="update-profile-image-container">
                  <img src={UpdateProfileBG} />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default EditProfileComponent;
