import { Breadcrumb } from "antd";
import { useNavigate } from "react-router-dom";
interface BreadCrumbRoute {
  title: string;
  path: string;
}
interface BreadCrumbProps {
  routes: BreadCrumbRoute[];
  lastItem: string;
}
const BreadCrumbComponent: React.FC<BreadCrumbProps> = ({
  routes,
  lastItem,
}) => {
  const navigate = useNavigate();
  return (
    <Breadcrumb style={{ margin: "16px 0" }}>
      {routes.map((item: BreadCrumbRoute) => {
        return (
          <Breadcrumb.Item
            key={Math.random()}
            className="cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate(`${item.path}`);
            }}
          >
            {item.title}
          </Breadcrumb.Item>
        );
      })}
      <Breadcrumb.Item>{lastItem}</Breadcrumb.Item>
    </Breadcrumb>
  );
};
export default BreadCrumbComponent;
