import { Breadcrumb } from "antd";
import DashboardComponent from "../components/dashboard/Index";
import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";

const HomePage = () => {
  return (
    <>
      <BreadCrumbComponent lastItem="Home" routes={[]} />
      <DashboardComponent />
    </>
  );
};
export default HomePage;
