import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Checkbox,
  Input,
  Typography,
  Select,
} from "antd";
import useAxiosPost from "../../hooks/useAxiosPost";
import "./index.css";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
interface SelectOptions {
  value: string;
  label: string;
}
const CreateUserComponent = () => {
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreateNewUserProp>("clients/createNewClient");
  const { openNotification, contextHolder } = useMultiNotification();

  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    console.log("Received values:", values);
    const { userName, password, contact, clientName } = values;

    const dataToSend = {
      password,
      userName,
      clientName,
      clientContact: contact,
    };
    postApiData(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiErrorCount, apiErrorCount]);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const { message } = apiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate("/users");
        }, 4000);
      }
    }
  }, [apiResponse]);
  return (
    <>
      {contextHolder}
      <Row className="create-package-container" gutter={[16, 16]}>
        <div className="general-form-container">
          <Typography className="general-form-title">Create User</Typography>

          <Form
            name="createBatchForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item
              label={<span className="general-input-label">FullName</span>}
              name="clientName"
              rules={[
                {
                  required: true,
                  message: "Please Enter User Full Name",
                },
                {
                  min: 4,
                  message: "Minimum 4 Characters are required",
                },
                {
                  max: 12,
                  message: "Max 12 Characters are required",
                },
              ]}
              style={{ marginBottom: "8px" }}
              // className="login-form-input-control"
            >
              <Input
                className="general-form-input-item"
                placeholder="Full Name"
              />
            </Form.Item>
            <Form.Item
              label={<span className="general-input-label">Username</span>}
              name="userName"
              rules={[
                {
                  required: true,
                  message: "Please Enter username",
                },
                {
                  min: 4,
                  message: "Minimum 4 Characters are required",
                },
                {
                  max: 12,
                  message: "Max 12 Characters are required",
                },
              ]}
              style={{ marginBottom: "8px" }}
              // className="login-form-input-control"
            >
              <Input
                className="general-form-input-item"
                placeholder="username"
              />
            </Form.Item>
            <Form.Item
              label={<span className="general-input-label">Password</span>}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please Enter Password",
                },
                {
                  min: 4,
                  message: "Minimum 4 Characters are required",
                },
                {
                  max: 12,
                  message: "Max 12 Characters are required",
                },
              ]}
              style={{ marginBottom: "8px" }}
              // className="login-form-input-control"
            >
              <Input
                className="general-form-input-item"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item
              label={<span className="general-input-label">Contact</span>}
              name="contact"
              rules={[
                {
                  required: true,
                  message: "Please Enter Phone Number",
                },
                {
                  min: 10,
                  message: "Minimum 10 Characters are required",
                },
                {
                  max: 11,
                  message: "Max 11 Characters are required",
                },
              ]}
              style={{ marginBottom: "8px" }}
              // className="login-form-input-control"
            >
              <Input
                className="general-form-input-item"
                placeholder="Phone Number"
              />
            </Form.Item>
            <Form.Item>
              <Button
                className="login-submit-button"
                type="primary"
                htmlType="submit"
              >
                Create User
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Row>
    </>
  );
};
export default CreateUserComponent;
