import { useEffect, useRef, useState } from "react";
import { Row, Col, Typography, Form, Input, Card, Select, Button } from "antd";
import type { SelectProps } from "antd";
import OnlineDevicesTable from "../Items/Table/OnlineDevicesTable";
import "./index.css";
import SwitchComponent from "../Items/Switch/SwitchComponent";
import SearchIconMultiColor from "./../../assets/svgs/NavBarIcons/SearchIconMultiColor.svg";
import useAxiosPost from "../../hooks/useAxiosPost";
import { formatDate } from "../../helpers/date";
interface SelectOptions {
  value: string;
  label: string;
}
const DevicesComponent = () => {
  const routerUsersResultsRef = useRef<any>(null);

  const [activeTab, setActiveTab] = useState<string>("online-users");
  const [actualDevices, setActualDevices] = useState<
    DevicesInfoTableDataType[]
  >([]);
  const [devices, setDevices] = useState<DevicesInfoTableDataType[]>([]);
  const [allRouters, setAllRouters] = useState<any>([]);
  const [selectedRouter, setSelectedRouter] = useState<string>("");
  const [routerOptions, setAllRouterOptions] = useState<SelectOptions[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allUsers, setAllUsers] = useState<any>([]);
  const [actualUsers, setActualUsers] = useState<any>([]);
  const [usersFilter, setUsersFilter] = useState<string>("");
  const userOptions: SelectProps["options"] = [
    {
      label: "All Users",
      value: "",
    },
    {
      label: "Authenticated",
      value: "1",
    },
  ];

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<OnlineUsersProp>("users/getOnlineUsers");
  useEffect(() => {
    postApiData({});
    getMyRoutersApi({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const responseData: any = apiResponse.responseData || [];
      console.log({ responseData });
      const devices = responseData.map(
        (item: any): DevicesInfoTableDataType => {
          return {
            formattedMacAddress: item.formattedMacAddress,
            macAddress: item.macAddress,
            upTime: formatDate(item.sessionStartTime).toString(),
            ipAddress: item.IP,
            dataConsumed: item.totalDataConsumption + "MB",
            intPkCompanySiteID: Number(
              item?.siteDetails?.intPkCompanySiteID || 0
            ),
            intPkSiteCoreRouterID: Number(
              item?.siteDetails?.intPkSiteCoreRouterID || 0
            ),
            routerName: item?.siteDetails?.routerName,
            routerToken: item?.siteDetails?.routerToken,
            siteName: item?.siteDetails?.siteName,
          };
        }
      );
      setDevices(devices);
      setActualDevices(devices);
      // setDevices(responseData || []);
    }
  }, [apiResponse]);
  const getActiveTab = (tab: string) => {
    console.log({ tab });
    setActiveTab(tab);
  };
  const [
    getMyRoutersApi,
    routersApiResponse,
    routersApiError,
    routersApiLoading,
    routersApiMessage,
    routersApiErrorCount,
    // setRequestUrl,
  ] = useAxiosPost<ResponseProp>("siteCoreRouters/getMyAllRouters");

  useEffect(() => {
    if (routersApiResponse !== null) {
      const data: any = routersApiResponse.responseData || [];
      setAllRouters(data);
      const routers = data.map((item: any): SelectOptions => {
        return {
          value: item.intPkSiteCoreRouterID,
          label: item.routerName,
        };
      });
      routers.unshift({
        value: "",
        label: "All Routers",
      });
      setAllRouterOptions(routers);
    }
  }, [routersApiResponse]);
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const [
    requestApi,
    requestApiResponse,
    requestApiError,
    requestApiLoading,
    requestApiMessage,
    requestApiErrorCount,
    setRequestUrl,
  ] = useAxiosPost<RouterRequestsAndResultsApisProp>(
    "routerRequest/requestAllConnectedUsersFromRouter"
  );

  const [
    resultApiCall,
    resultApiResponse,
    resultApiError,
    resultApiLoading,
    resultApiMessage,
    resultApiErrorCount,
    setResultUrl,
  ] = useAxiosPost<RouterRequestsAndResultsApisProp>(
    "routerResults/getAllConnectedUsersFromRouter"
  );

  const startIntervalHandler = () => {
    routerUsersResultsRef.current = setInterval(() => {
      console.log("new api call");
      //we'll call the result api here
      resultApiCall({
        routerId: selectedRouter,
      });
    }, 2000);
    setTimeout(() => {
      clearInterval(routerUsersResultsRef.current);
      setIsLoading(false);
    }, 30000);
  };
  useEffect(() => {
    if (requestApiResponse !== null) {
      const success: any = requestApiResponse.success;
      if (success === true) {
        //let's call the interval baby
        setTimeout(() => {
          console.log("we are going to call interval from now on.");
          startIntervalHandler();
        }, 5000);
      }
    }
  }, [requestApiResponse]);

  useEffect(() => {
    if (resultApiResponse !== null) {
      const data: any = resultApiResponse.responseData;
      const { users } = data;
      console.log(users);
      setAllUsers(users || []);
      setActualUsers(users || []);

      if (users.length > 0) {
        if (usersFilter === "CONNECTED_USERS") {
        } else if (usersFilter === "AUTHENTICATED_USERS") {
          const _users = users.filter(
            (item: any) => item.isAuthorized === true
          );
          setAllUsers(_users || []);
        } else if (usersFilter === "UN_AUTHENTICATED_USERS") {
          const _users = users.filter(
            (item: any) => item.isAuthorized === false
          );
          setAllUsers(_users || []);
        }

        setIsLoading(false);
        clearInterval(routerUsersResultsRef.current);
      }
    }
  }, [resultApiResponse]);

  return (
    <>
      {/* <h1>Dashboard</h1> */}
      <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
        <Col
          span={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Select
            size={"large"}
            defaultValue=""
            options={routerOptions}
            style={{ width: "39%" }}
            optionFilterProp="children"
            filterOption={filterOption}
            showSearch
            value={selectedRouter}
            onChange={(value) => {
              setSelectedRouter(value);

              if (value === "") {
                setDevices(actualDevices);
                return;
              }
              setDevices(
                actualDevices.filter(
                  (item) =>
                    item.intPkSiteCoreRouterID.toString() === value.toString()
                )
              );
            }}
          />
          {activeTab !== "online-users" ? (
            <>
              <Select
                placeholder="All Routers"
                size={"large"}
                defaultValue=""
                style={{ width: "39%" }}
                optionFilterProp="children"
                filterOption={filterOption}
                showSearch
                options={routerOptions}
              />

              <Button className="fetch-devices-button" style={{ width: "19%" }}>
                Fetch
              </Button>
            </>
          ) : null}
        </Col>
        <Col span={12}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <SwitchComponent sendActiveTab={getActiveTab} />
            </Col>
            <Col span={12}>
              <Button className="search-devices-button">
                <div className="button-content">
                  <img src={SearchIconMultiColor} />
                  <span>Search Users</span>
                </div>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          {activeTab === "online-users" ? (
            <OnlineDevicesTable devices={devices} />
          ) : null}
        </Col>
      </Row>
    </>
  );
};
export default DevicesComponent;
