import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import UserDetailsComponent from "../components/users/UserDetailsComponent";

const UserDetailsPage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="User Details"
        routes={[
          { title: "Home", path: "/" },
          { title: "Users", path: "/users" },
        ]}
      />
      <UserDetailsComponent />
    </>
  );
};
export default UserDetailsPage;
