import { Typography, Card, Divider, Dropdown } from "antd";
import "./siteCard.css";
import LinkIcon from "./../../../../assets/svgs/linkIcon/LinkIcon.svg";
import MenuIcon from "./../../../../assets/svgs/OptionsMenuIcon/MenuIcon.svg";
import OnlineStatusIndicator from "../../Status/OnlineStatusIndicator";
import UsersIcon from "./../../../../assets/svgs/IconsBlack/UsersIconBlack.svg";
import OnlineUsersIcon from "./../../../../assets/svgs/IconsBlack/OnlineUsersBlack.svg";
import CapacityIcon from "./../../../../assets/svgs/IconsBlack/CapacityIconBlack.svg";
import type { MenuProps } from "antd";
import { useNavigate } from "react-router-dom";

interface SiteCounterBox {
  title: string;
  value: string | number;
  icon: any;
}
const SiteCounterBox: React.FC<SiteCounterBox> = ({ title, value, icon }) => {
  return (
    <div className="site-card-counter-box">
      <div className="site-card-counters-image">
        <img src={icon} />
      </div>
      <Typography className="site-card-counters-title">{title}</Typography>
      <Typography className="site-card-counters-value">{value}</Typography>
    </div>
  );
};

const SiteCard: React.FC<SiteCardProp> = ({
  siteName,
  siteAddress,
  intPkCompanyID,
  intPkCompanySiteID,
}) => {
  const navigate = useNavigate();

  const onClick: MenuProps["onClick"] = ({ key }) => {
    console.log("Hi", key);
  };
  const items: MenuProps["items"] = [
    {
      key: "siteDetails",
      label: <span>Site Details</span>,
    },

    {
      key: "refresh",
      label: <span>Refresh</span>,
    },
  ];

  return (
    <Card className="border-site-card">
      <div className="site-card-top-items">
        <div className="site-details-box">
          <div className="site-title-box">
            <Typography className="site-header-title">{siteName}</Typography>
            <img
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/site-details/${intPkCompanySiteID}`);
              }}
              src={LinkIcon}
            />
          </div>
          <div>
            <Typography className="site-header-address">
              {siteAddress}
            </Typography>
          </div>
        </div>
        <div className="site-status-indicator-box">
          <OnlineStatusIndicator isOnline={true} /> ON
        </div>
        <div className="site-options-opener-container">
          <Dropdown
            menu={{ items, onClick }}
            trigger={["click"]}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <img className="cursor-pointer" src={MenuIcon} />
          </Dropdown>
        </div>
      </div>
      <Divider
        style={{ borderStyle: "dashed", borderWidth: 1, color: "#DCDCDC" }}
      />

      {/* First Section End */}

      <div className="site-card-package-details">
        <Typography className="site-package-label">Package</Typography>
        <Typography className="site-package-title">Platinum Grace</Typography>
      </div>
      <Divider
        style={{ borderStyle: "dashed", borderWidth: 1, color: "#DCDCDC" }}
      />
      {/* Second Section End */}
      <div className="site-card-counters-container">
        <SiteCounterBox title={`Total users`} value={80} icon={UsersIcon} />
        <SiteCounterBox title={`Online`} value={80} icon={OnlineUsersIcon} />
        <SiteCounterBox title={`Capacity`} value={120} icon={CapacityIcon} />
      </div>
    </Card>
  );
};
export default SiteCard;
