import React from "react";
import "./App.css";
import MyComponent from "./MyComponent";
import RoutesWrapper from "./routes";

function App() {
  return <RoutesWrapper />;
}

export default App;
