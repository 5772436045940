import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import PackagesComponent from "../components/packages/PackagesComponent";

const PackagesPage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="Packages"
        routes={[{ title: "Home", path: "/" }]}
      />
      <PackagesComponent />
    </>
  );
};
export default PackagesPage;
