import { useEffect, useState } from "react";
import { Row, Col, Card, Typography, Dropdown, Button, Input } from "antd";
import "./index.css";
import type { MenuProps } from "antd";
import UsersTable from "../Items/Table/UsersTable";
import SearchIconMultiColor from "./../../assets/svgs/NavBarIcons/SearchIconMultiColor.svg";
import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import { formatDateForJustMonthDayAndYear } from "../../helpers/date";

const UserListComponent = () => {
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("clients/getClientUsers");
  const [users, setUsers] = useState<UsersInfoTableDataType[]>([]);

  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const responseData: any = apiResponse.responseData || [];
      setUsers(
        responseData.map((item: any): UsersInfoTableDataType => {
          return {
            userName: item.clientName,
            status: item.clientStatus,
            packageName: "2MB30GB",
            expiryDate: item.expiryDate
              ? formatDateForJustMonthDayAndYear(item.expiryDate).toString()
              : "-",
            intPkAuthenticatedDeviceID: item.intPkAuthenticatedDeviceID || null,
            intPkClientID: Number(item.intPkClientID),
            onlineStatus: item.onlineStatus,
          };
        })
      );
    }
  }, [apiResponse]);

  const onClick: MenuProps["onClick"] = ({ key }) => {
    console.log("Hi", key);
  };
  const items: MenuProps["items"] = [
    {
      key: "siteDetails",
      label: <span>Site Details</span>,
    },

    {
      key: "refresh",
      label: <span>Refresh</span>,
    },
  ];
  return (
    <>
      <Row style={{ marginBottom: "20px" }}>
        <Col span={12}></Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          {/* Here Comes Our Controls */}
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate("/create-user");
            }}
            className="fetch-devices-button"
            style={{ minWidth: "25%" }}
          >
            Create User
          </Button>
          <Input placeholder="Search User" />
          <Button type="primary" className="search-users-button">
            <img src={SearchIconMultiColor} />
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <UsersTable users={users} isActionButton={true} />
        </Col>
      </Row>
    </>
  );
};
export default UserListComponent;
