import { useEffect, useState } from "react";
import { Row, Col, Card, Typography, Button, Form, Input } from "antd";
import "./index.css";
import UpdatePasswordBG from "./../../../assets/pngs/UpdatePasswordBG.png";
import useMultiNotification from "../../../hooks/useNotification";
import useAxiosPost from "../../../hooks/useAxiosPost";

const ChangePasswordComponent = () => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [
    postUpdateProfileDataApi,
    ProfileDataAApiResponse,
    ProfileDataAApiError,
    ProfileDataAApiLoading,
    ProfileDataAApiMessage,
    ProfileDataAApiErrorCount,
  ] = useAxiosPost<UpdateMyPasswordProp>("auth/updatePassword");
  useEffect(() => {
    if (ProfileDataAApiError !== null && ProfileDataAApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", ProfileDataAApiError);
    }
  }, [ProfileDataAApiError, ProfileDataAApiErrorCount]);
  useEffect(() => {
    if (ProfileDataAApiResponse !== null) {
      // userType, email
      const { message } = ProfileDataAApiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
      }
    }
  }, [ProfileDataAApiResponse]);
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    console.log("Received values:", values);
    const { password, newPassword, confirmPassword } = values;
    if (newPassword !== confirmPassword) {
      openNotification(
        "error",
        "Validation Error",
        "New and confirm Password Mismatch"
      );
      return;
    }
    const dataToSend = {
      password,
      newPassword,
      confirmPassword,
    };
    postUpdateProfileDataApi(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      {contextHolder}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className="profile-container">
            <div className="user-profile-icon">
              {/* <img src={ProfileBG} /> */}
              <Typography className="user-profile-initial">H</Typography>
            </div>
          </div>
          <Card className="border-card ">
            <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
              <Col span={12}>
                <Typography className="update-profile-heading">
                  {" "}
                  Change Password
                </Typography>
                <Typography className="update-profile-description">
                  Its good to change your password periodically, Please provide
                  us your old password and select new password.
                </Typography>
                <Form
                  className="login-form"
                  name="emailForm"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  requiredMark={false}
                >
                  <Form.Item
                    label={
                      <span className="login-form-input-label">
                        Old Password
                      </span>
                    }
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Password",
                      },
                      {
                        min: 6,
                        message: "Minimum Characters for password are 6",
                      },
                    ]}
                    style={{ marginBottom: "8px", color: "blue" }}
                    className="login-form-input-control"
                  >
                    <Input.Password
                      className="login-form-input-item"
                      placeholder="Enter your Password"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span className="login-form-input-label">
                        New Password
                      </span>
                    }
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Password",
                      },
                      {
                        min: 6,
                        message: "Minimum Characters for password are 6",
                      },
                    ]}
                    style={{ marginBottom: "8px", color: "blue" }}
                    className="login-form-input-control"
                  >
                    <Input.Password
                      className="login-form-input-item"
                      placeholder="Enter your New Password"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span className="login-form-input-label">
                        Retype New Password
                      </span>
                    }
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Password",
                      },
                      {
                        min: 6,
                        message: "Minimum Characters for password are 6",
                      },
                    ]}
                    style={{ marginBottom: "8px", color: "blue" }}
                    className="login-form-input-control"
                  >
                    <Input.Password
                      className="login-form-input-item"
                      placeholder="Retype New Password"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="change-password-button"
                      type="primary"
                      htmlType="submit"
                    >
                      Update Profile
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={12}>
                <div className="update-profile-image-container">
                  <img src={UpdatePasswordBG} />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default ChangePasswordComponent;
