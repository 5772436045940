import ForgotPasswordComponent from "../components/auth/ForgotPasswordComponent";

const ForgotPasswordPage = () => {
  return (
    <>
      <ForgotPasswordComponent />
    </>
  );
};
export default ForgotPasswordPage;
