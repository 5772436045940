// intPkClientID
import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import AssignPackageToUserComponent from "../components/packages/AssignPackageToUser";
const AssignPackageToUserPage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="Assign Package"
        routes={[
          { title: "Home", path: "/" },
          { title: "Users", path: "/users" },
        ]}
      />
      <AssignPackageToUserComponent />{" "}
    </>
  );
};
export default AssignPackageToUserPage;
