import { useEffect, useState } from "react";
import { Row, Col, Card, Typography, Dropdown, Button } from "antd";
import "./index.css";
import type { MenuProps } from "antd";
import OnlineStatusIndicator from "../Items/Status/OnlineStatusIndicator";
import MenuIcon from "./../../assets/svgs/OptionsMenuIcon/MenuIcon.svg";
import useAxiosPost from "../../hooks/useAxiosPost";
import {
  formatDateForJustMonthDayAndYear,
  secondsToTime,
} from "../../helpers/date";
import { useNavigate, useParams } from "react-router-dom";
import useMultiNotification from "../../hooks/useNotification";
interface SingleRowUserProp {
  title: string;
  value: string;
  bgGray: boolean;
}
const UserSingleRow: React.FC<SingleRowUserProp> = ({
  title,
  value,
  bgGray,
}) => {
  return (
    <div
      className={`user-details-left-container-row ${
        bgGray === true ? "user-bg-gray" : ""
      }`}
    >
      <div className="user-details-label-container">
        <Typography>{title}</Typography>
      </div>
      <div className="user-details-value-container">
        <Typography>{value}</Typography>
      </div>
      <div></div>
    </div>
  );
};

const UserDetailsComponent = () => {
  const { contextHolder, openNotification } = useMultiNotification();
  const navigate = useNavigate();
  const { intPkClientID } = useParams();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<SingleClientApiProp>("clients/getSingleClientDetails");

  const [
    handleDisableUserApi,
    disableUserApiResponse,
    disableUserApiError,
    disableUserApiLoading,
    disableUserApiMessage,
    disableUserApiErrorCount,
  ] = useAxiosPost<SingleClientApiProp>("clients/enableDisableClient");

  const [userDetails, setUserDetails] = useState<any>({});
  useEffect(() => {
    postApiData({ intPkClientID: Number(intPkClientID) });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      // userType, email
      const responseData: any = apiResponse.responseData || {};
      setUserDetails(responseData);
    }
  }, [apiResponse]);

  useEffect(() => {
    if (disableUserApiResponse !== null) {
      // userType, email
      const { message } = disableUserApiResponse;
      if (message) {
        openNotification("success", "Status Updated", "User Status Updated");
      }
      postApiData({ intPkClientID: Number(intPkClientID) });
    }
  }, [disableUserApiResponse]);

  const onClick: MenuProps["onClick"] = ({ key }) => {
    console.log("Hi", key);
  };
  const items: MenuProps["items"] = [
    {
      key: "siteDetails",
      label: <span>Site Details</span>,
    },

    {
      key: "refresh",
      label: <span>Refresh</span>,
    },
  ];
  return (
    <>
      {contextHolder}
      <Row style={{ marginBottom: "20px" }}>
        <Col span={14}></Col>
        <Col
          span={10}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* Here Comes Our Buttons */}
          {/* We don't need kick button for now. */}
          {/* 
          
          
          <Button
            type="primary"
            danger
            style={{ minWidth: "20%", minHeight: "40px", fontSize: "18px" }}
            disabled={true}
            onClick={(e) => {
              e.preventDefault();
              if (userDetails.onlineStatus !== true) {
                return;
              }
              //let's kick this
            }}
          >
            Kick User
          </Button>
          
          
          */}
          <Button className="fetch-devices-button" style={{ minWidth: "20%" }}>
            Pause Policy
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/assign-package/${intPkClientID}`);
            }}
            className="fetch-devices-button"
            style={{ minWidth: "20%" }}
          >
            Assign Package
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleDisableUserApi({ intPkClientID: Number(intPkClientID) });
            }}
            disabled={disableUserApiLoading}
            className="fetch-devices-button"
            style={{ minWidth: "20%" }}
          >
            Disable User
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          {/* Here we have the basic details of user */}
          <Card className="border-card">
            <div className="user-details-left-container-row">
              <Typography className="user-fullName">
                {userDetails.clientName}
              </Typography>

              <div className="user-status-indicator-box">
                <OnlineStatusIndicator
                  isOnline={userDetails.onlineStatus || false}
                />{" "}
                {userDetails.onlineStatus === true ? "Online" : "Offline"}
              </div>
              <div className="site-options-opener-container">
                <Dropdown
                  menu={{ items, onClick }}
                  trigger={["click"]}
                  placement="bottomRight"
                  arrow={{ pointAtCenter: true }}
                >
                  <img className="cursor-pointer" src={MenuIcon} />
                </Dropdown>
              </div>
            </div>
            <UserSingleRow
              title="User ID"
              value={userDetails.userName}
              bgGray={true}
            />
            <UserSingleRow
              title="Password"
              value={userDetails.password}
              bgGray={false}
            />

            <UserSingleRow
              title="Site"
              value={userDetails?.siteDetails?.siteName || "-"}
              bgGray={true}
            />
            <UserSingleRow title="Package" value="8MB50GB" bgGray={false} />
            <UserSingleRow
              title="Date Started"
              value={
                userDetails?.subscriptionDetails?.dateCreated
                  ? formatDateForJustMonthDayAndYear(
                      userDetails?.subscriptionDetails?.dateCreated
                    ).toString()
                  : "-"
              }
              bgGray={true}
            />
            <UserSingleRow
              title="Expiry"
              value={
                userDetails?.subscriptionDetails?.expiryDate
                  ? formatDateForJustMonthDayAndYear(
                      userDetails?.subscriptionDetails?.expiryDate
                    ).toString()
                  : "-"
              }
              bgGray={false}
            />
            <UserSingleRow
              title="Mac Address"
              value={
                userDetails.formattedMacAddress
                  ? userDetails.formattedMacAddress
                  : "-"
              }
              bgGray={true}
            />
            <UserSingleRow
              title="IP Address"
              value={
                userDetails?.siteDetails?.eUserDevIP
                  ? userDetails?.siteDetails?.eUserDevIP
                  : "-"
              }
              bgGray={false}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card className="border-card">
            <Typography
              style={{ marginBottom: "10px" }}
              className="user-right-container-title"
            >
              Today's Stats{" "}
            </Typography>
            <UserSingleRow
              title="Connected Since"
              value={
                userDetails?.siteDetails?.sessionTotalUptimeSeconds
                  ? secondsToTime(
                      userDetails?.siteDetails?.sessionTotalUptimeSeconds
                    )
                  : "-"
              }
              bgGray={true}
            />
            {/* <UserSingleRow title="Password" value="Data Usage" bgGray={false} /> */}
          </Card>
          <Card style={{ marginTop: "20px" }} className="border-card">
            <Typography
              style={{ marginBottom: "10px" }}
              className="user-right-container-title"
            >
              Total Stats{" "}
            </Typography>
            <UserSingleRow title="Data Usage" value="12.356 GB" bgGray={true} />
            <UserSingleRow title="Remaining" value="37.644 GB" bgGray={false} />
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default UserDetailsComponent;
