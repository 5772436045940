import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

import { AppRoutes } from "./data";
import AppLayout from "./components/Layout/Layout";
import MyComponent from "./MyComponent";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import { getUserAuthToken } from "./helpers/userDetails";
import ForgotPasswordPage from "./pages/ForgotPage";
import OTPInputPage from "./pages/OTPInputPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";

const RoutesWrapper = () => {
  const isLoggedIn = getUserAuthToken() !== "" ? true : false; //localStorage.getItem("auth_token") || false;
  // const isLoggedIn = true;
  const getHomePage = () => {
    if (isLoggedIn === true) {
      return <></>;
    }
    return <LoginPage />;
  };

  return (
    <Router>
      {isLoggedIn && <AppLayout />}
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/forgot-password-otp" element={<OTPInputPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />

        <Route path="/" element={getHomePage()} />
      </Routes>
    </Router>
  );
};

export default RoutesWrapper;
