import React from "react";
import "./index.css";
import { Space, Table, Tag, Typography, Badge, Button } from "antd";
import type { ColumnsType } from "antd/es/table";
import ThreeDotsOptionsMenuIcon from "./../../../assets/svgs/OptionsMenuIcon/ThreeDotsOptionsMenuIcon.svg";

const OnlineDevicesTable: React.FC<DevicesTableProp> = ({ devices }) => {
  /** 
  status: string;
  intPkClientID: string | number; */

  const columns: ColumnsType<DevicesInfoTableDataType> = [
    {
      title: "Mac Address",
      dataIndex: "formattedMacAddress",
      key: "formattedMacAddress",
    },
    {
      title: "Ip Address",
      dataIndex: "ipAddress",
      key: "ipAddress",
    },
    {
      title: "Up Time",
      dataIndex: "upTime",
      key: "upTime",
    },
    {
      title: "Data Consumed",
      dataIndex: "dataConsumed",
      key: "dataConsumed",
    },
    {
      title: "Router Name",
      dataIndex: "routerName",
      key: "routerName",
    },

    {
      title: "Action",
      key: "action",
      render: (_, {}) => {
        return (
          <>
            <img className="cursor-pointer" src={ThreeDotsOptionsMenuIcon} />
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="table-container-custom">
        <Table
          className="antd-table-striped"
          columns={columns}
          dataSource={devices}
          pagination={false}
        />
      </div>
    </>
  );
};

export default OnlineDevicesTable;
