import moment from "moment";
export const formatDate = (date: Date) => {
  return moment(date).format("D-MMMM-YYYY hh:mm:ss A");
};

export const formatDateForJustMonthDayAndYear = (date: Date) => {
  return moment(date).format("DD-MM-YY");
};
export const secondsToTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const timeParts = [];

  if (hours > 0) {
    timeParts.push(hours < 10 ? `0${hours}` : hours);
  } else {
    timeParts.push("00");
  }

  if (minutes > 0 || hours > 0) {
    timeParts.push(minutes < 10 ? `0${minutes}` : minutes);
  } else {
    timeParts.push("00");
  }

  timeParts.push(
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
  );

  return timeParts.join(":");
};
export const getDurationFromTariffUnit = (unit: string) => {
  if (unit === "H") {
    return "Hours";
  }
  if (unit === "D") {
    return "Days";
  }
  if (unit === "M") {
    return "Months";
  }
  return "Hours";
};
