import BreadCrumbComponent from "../components/Items/BreadCrumb/Index";
import SitesComponent from "../components/sites/SitesComponent";

const SitesPage = () => {
  return (
    <>
      <BreadCrumbComponent
        lastItem="Sites"
        routes={[{ title: "Home", path: "/" }]}
      />
      <SitesComponent />
    </>
  );
};
export default SitesPage;
