import { Typography, Card, Divider, Dropdown, Col, Row } from "antd";
import "./batchCard.css";
import MenuIcon from "./../../../../assets/svgs/OptionsMenuIcon/MenuIcon.svg";
import type { MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
const BatchSingleRowViewer: React.FC<BatchSingleRowViewerProp> = ({
  title,
  value,
  valueSpecialClass = "",
}) => {
  return (
    <Row gutter={[6, 16]}>
      <Col span={12}>
        <Typography className={`batch-details-label `}>{title}</Typography>
      </Col>
      <Col span={12}>
        <Typography className={`batch-details-value ${valueSpecialClass}`}>
          {value}
        </Typography>
      </Col>
    </Row>
  );
};
const BatchCard: React.FC<BatchCardProp> = ({
  isDisabled,
  batchName,
  intPkBatchID,
  speed,
  dataVolume,
  usedVouchers,
  remainingVouchers,
  tariffName,
  dateCreated,
  batchStatus,
}) => {
  const navigate = useNavigate();
  const onClick: MenuProps["onClick"] = ({ key }) => {
    console.log("Hi", key);
  };
  const items: MenuProps["items"] = [
    {
      key: "siteDetails",
      label: <span className="drop-down-item-custom">Site Details</span>,
    },

    {
      key: "refresh",
      label: <span className="drop-down-item-custom">Refresh</span>,
    },
  ];

  return (
    <Card
      className={`border-card batch-card-container ${
        isDisabled ? "batch-card-disabled" : ""
      }`}
    >
      {/* Top Header */}
      <div className="batch-card-top-items">
        <div className="batch-card-title">
          <Typography
            className="batch-header-title cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/batch-details/${intPkBatchID}`);
            }}
          >
            {batchName}
          </Typography>
        </div>

        <div className="package-options-opener-container">
          {batchStatus === "PENDING" ? (
            <span
              style={{ color: "#FF7575", fontSize: "16px", fontWeight: "400" }}
            >
              {batchStatus}{" "}
            </span>
          ) : null}

          <Dropdown
            className="custom-drop-down"
            menu={{ items, onClick }}
            trigger={["click"]}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <img className="cursor-pointer" src={MenuIcon} />
          </Dropdown>
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={8} className="batch-left-container">
          <div className="batch-left-container-values">
            <div>
              <Typography className="batch-speed-label">{speed}</Typography>
              <Typography className="batch-duration-label">
                {tariffName}
              </Typography>
            </div>
            <div>
              <Typography className="batch-volume-label">
                {dataVolume}
              </Typography>
              <Typography className="batch-policy-label">24/7</Typography>
            </div>
          </div>
          <div className="vertical-divider"></div>
        </Col>
        <Col span={16} className="batch-right-container">
          <BatchSingleRowViewer title="Create On" value={dateCreated} />
          <BatchSingleRowViewer
            title="Used Vouchers"
            value={usedVouchers.toString()}
          />
          <BatchSingleRowViewer
            title="Remaining"
            value={remainingVouchers.toString()}
          />
        </Col>
      </Row>
    </Card>
  );
};
export default BatchCard;
